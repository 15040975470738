import {
    Action,
    Box,
    BoxProps,
    Button,
    ButtonStyle,
    Checkbox,
    ContentBlock,
    FieldBlock,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    IconTooltip,
    InputDate,
    InputDateType,
    InputText,
    InputUrl,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    Table,
    TableActionColumn,
    TableColumn,
    TableRow,
    Tabs,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiService} from "../../api/model/ApiService";
import {PortalContentField} from "../../api/model/portal-content/PortalContentField";
import {PORTAL_CONTENT_STATUSES} from "../../api/model/portal-content/PortalContentStatus";
import {PortalSetting} from "../../api/model/portal-setting/PortalSetting";
import {PortalSettingContent} from "../../api/model/portal-setting/PortalSettingContent";
import {PortalSettingContentField} from "../../api/model/portal-setting/PortalSettingContentField";
import {PortalSettingContentFooter} from "../../api/model/portal-setting/PortalSettingContentFooter";
import {PortalSettingContentFooterField} from "../../api/model/portal-setting/PortalSettingContentFooterField";
import {PortalSettingContentFooterItem} from "../../api/model/portal-setting/PortalSettingContentFooterItem";
import {PortalSettingContentFooterItemField} from "../../api/model/portal-setting/PortalSettingContentFooterItemField";
import {PortalSettingContentHeader} from "../../api/model/portal-setting/PortalSettingContentHeader";
import {PortalSettingContentHeaderField} from "../../api/model/portal-setting/PortalSettingContentHeaderField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {Locale} from "../../common/utils/Locale";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {TranslationCommonFile} from "../../common/utils/constants";
import {Formatter} from "../../common/utils/Formatter";
import {HeaderModule} from "../../common/utils/HeaderModule";
import {detectChanges} from "../../common/utils/portal";
import {validateUrl} from "../../common/utils/string";
import {Theme} from "../../common/utils/Theme";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {MainContentHeader, MainContentHeaderAction, SelectService, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";

function PortalSettingsDetails() {
    const {t: textPortalSettings} = useTranslation(TranslationPortalFile.PORTAL_SETTINGS);
    const {t: textPortalContent} = useTranslation(TranslationPortalFile.PORTAL_CONTENT);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {id} = useParams() as {id: string};
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());
    const [initPortalSetting, setInitPortalSetting] = useState<PortalSetting>(new PortalSetting());
    const [newLabel, setNewLabel] = useState<string>("");
    const [newPath, setNewPath] = useState<string>("");
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);
    const alert = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const portalSetting = await session.restPortalContent.get(PortalSetting, +id);
                const newPortalSetting = new PortalSetting({
                    ...portalSetting,
                    [PortalContentField.START_DATE]: portalSetting.start_date ? Formatter.convertUTCToDate(portalSetting.start_date).format(Formatter.API_DATETIME_FORMAT) : undefined
                });
                setPortalSetting(newPortalSetting);
                setInitPortalSetting(new PortalSetting(newPortalSetting));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("portal setting", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, id]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(portalSetting, initPortalSetting));
    }, [portalSetting, initPortalSetting]);

    const handleSave = async () => {
        try {
            const newPortalSetting = new PortalSetting({
                ...portalSetting,
                [PortalContentField.START_DATE]: Formatter.convertDateToUTC(portalSetting.start_date)
            });
            await session.restPortalContent.update(PortalSetting, newPortalSetting);
            setPortalSetting(portalSetting);
            setInitPortalSetting(new PortalSetting(portalSetting));
            alert.updateWithSuccess("portal setting");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("portal setting", e.message);
            }
        }
    };

    const handleDeletePortalSetting = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restPortalContent.delete(portalSetting.id);
            navigate(Module.PORTAL_SETTINGS.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("portal setting", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleChange = (field: PortalContentField, value: any) => {
        setPortalSetting((prevState) => new PortalSetting({
            ...prevState,
            [field]: value
        }));
    };

    const handleChangeContent = (field: PortalSettingContentField, value: any) => {
        handleChange(PortalContentField.CONTENT, new PortalSettingContent({
            ...portalSetting.content,
            [field]: value
        }));
    };

    const handleChangeContentHeader = (field: PortalSettingContentHeaderField, value: any) => {
        handleChangeContent(PortalSettingContentField.HEADER, new PortalSettingContentHeader({
            ...portalSetting.content.header,
            [field]: value
        }));
    };

    const handleChangeHeaderModule = (headerModule: HeaderModule, value: boolean) => {
        let newModules: string[];
        if (value) {
            newModules = [...portalSetting.content.header.modules, headerModule.name];
        } else {
            newModules = portalSetting.content.header.modules.filter((it) => it !== headerModule.name);
        }

        handleChangeContentHeader(PortalSettingContentHeaderField.MODULES, newModules);
    };

    const handleChangeFooter = (field: PortalSettingContentFooterField, value: any) => {
        handleChangeContent(PortalSettingContentField.FOOTER, new PortalSettingContentFooter({
            ...portalSetting.content.footer,
            [field]: value
        }));
    };

    const handleAddFooterItem = (label: string, path: string, locale: Locale) => {
        setNewLabel("");
        setNewPath("");
        const mapFooterItems = new Map(portalSetting.content.footer.items);
        const itemsForLocale = portalSetting.content.footer.getItemsForLocale(locale) || [];
        mapFooterItems?.set(locale, [...itemsForLocale, PortalSettingContentFooterItem.create(label, path)]);
        handleChangeFooter(PortalSettingContentFooterField.ITEMS, mapFooterItems);
    };

    const handleRemoveFooterItem = (item: PortalSettingContentFooterItem, locale: Locale) => {
        const mapFooterItems = new Map(portalSetting.content.footer.items);
        const itemsForLocale = portalSetting.content.footer.getItemsForLocale(locale) || [];
        mapFooterItems.set(locale, itemsForLocale.filter((it) => it.path !== item.path));
        handleChangeFooter(PortalSettingContentFooterField.ITEMS, mapFooterItems);
    };

    const hasEmptyField = () => {
        return !portalSetting.status || !portalSetting.title || !portalSetting.string_identifier || !portalSetting.service || !portalSetting.start_date || !portalSetting.content.theme;
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.PORTAL_SETTINGS} element={initPortalSetting.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.PORTAL_SETTINGS.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={hasEmptyField() || !isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textPortalSettings("section.information")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutColumns columns={4}>
                                            <FieldBlock label={textPortalContent(`field.${PortalContentField.STATUS}`)} required>
                                                <SelectStatus
                                                    value={portalSetting.status}
                                                    statuses={PORTAL_CONTENT_STATUSES.filter((it) => it.name !== Status.DELETED.name)}
                                                    onChange={(status) => handleChange(PortalContentField.STATUS, status?.name)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textPortalContent(`field.${PortalContentField.TITLE}`)} required>
                                                <InputText
                                                    value={portalSetting.title}
                                                    onChange={(value) => handleChange(PortalContentField.TITLE, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textPortalContent(`field.${PortalContentField.STRING_IDENTIFIER}`)} required>
                                                <InputText
                                                    value={portalSetting.string_identifier}
                                                    onChange={(value) => handleChange(PortalContentField.STRING_IDENTIFIER, value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textPortalContent(`field.${PortalContentField.SERVICE}`)} required>
                                                <SelectService
                                                    value={portalSetting.service}
                                                    services={ApiService.portals()}
                                                    onChange={(service) => handleChange(PortalContentField.SERVICE, service?.name || "")}
                                                    disabled
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textPortalSettings(`field.${PortalSettingContentField.THEME}`)} required>
                                                <Select
                                                    value={portalSetting.content.theme}
                                                    options={Theme.values().map((theme) => ({
                                                        value: theme.name,
                                                        label: textMain(`${PortalSettingContentField.THEME}.${theme.name}`)
                                                    }))}
                                                    onChange={(option) => handleChangeContent(PortalSettingContentField.THEME, option?.value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textPortalContent(`field.${PortalContentField.START_DATE}`)} required>
                                                <InputDate
                                                    type={InputDateType.DATETIME_LOCAL}
                                                    value={portalSetting.start_date}
                                                    onChange={(value) => handleChange(PortalContentField.START_DATE, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textPortalContent(`field.${PortalContentField.END_DATE}`)}>
                                                <InputDate
                                                    type={InputDateType.DATETIME_LOCAL}
                                                    value={portalSetting.end_date || ""}
                                                    onChange={(value) => handleChange(PortalContentField.END_DATE, value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textPortalSettings("section.settings")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutTitle>{textPortalSettings(`field.${PortalSettingContentField.HEADER}.`)}</FormLayoutTitle>
                                        <FieldBlock
                                            label={textPortalSettings(`field.${PortalSettingContentField.HEADER}.${PortalSettingContentHeaderField.MODULES}`)}
                                            content={{spacing: FlexContentSpacing.MEDIUM}}
                                        >
                                            {HeaderModule.values().map((headerModule) =>
                                                <Checkbox
                                                    key={headerModule.name}
                                                    name={headerModule.name}
                                                    label={textMain(`header_modules.${headerModule.name}`)}
                                                    checked={portalSetting.content.header.hasModule(headerModule)}
                                                    onChange={(value) => handleChangeHeaderModule(headerModule, value)}
                                                />
                                            )}
                                        </FieldBlock>
                                        <FormLayoutSeparator/>
                                        <FormLayoutTitle>{textPortalSettings(`field.${PortalSettingContentField.FOOTER}.`)}</FormLayoutTitle>
                                        <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                            <ToggleSwitch
                                                label={textPortalSettings(`field.${PortalSettingContentField.FOOTER}.${PortalSettingContentFooterField.LEGAL_NOTICE}`)}
                                                name={PortalSettingContentFooterField.LEGAL_NOTICE}
                                                checked={portalSetting.content.footer.legal_notice}
                                                onChange={(value) => handleChangeFooter(PortalSettingContentFooterField.LEGAL_NOTICE, value)}
                                            />
                                            <ToggleSwitch
                                                label={textPortalSettings(`field.${PortalSettingContentField.FOOTER}.${PortalSettingContentFooterField.COOKIES_SETTINGS}`)}
                                                name={PortalSettingContentFooterField.COOKIES_SETTINGS}
                                                checked={portalSetting.content.footer.cookies_settings}
                                                onChange={(value) => handleChangeFooter(PortalSettingContentFooterField.COOKIES_SETTINGS, value)}
                                            />
                                        </FormLayoutRows>
                                        <FieldBlock
                                            label={textPortalSettings(`field.${PortalSettingContentField.FOOTER}.${PortalSettingContentFooterField.ITEMS}.`)}
                                        >
                                            <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                                                {Locale.values().map((locale) =>
                                                    <FormLayoutRows key={`${locale}`} spacing={FlexContentSpacing.SMALL}>
                                                        <FieldBlock>
                                                            <InputText
                                                                value={newLabel}
                                                                placeholder={textPortalSettings(`field.${PortalSettingContentField.FOOTER}.${PortalSettingContentFooterField.ITEMS}.${PortalSettingContentFooterItemField.LABEL}`)}
                                                                onChange={setNewLabel}
                                                            />
                                                            <InputUrl
                                                                value={newPath}
                                                                placeholder={textPortalSettings(`field.${PortalSettingContentField.FOOTER}.${PortalSettingContentFooterField.ITEMS}.${PortalSettingContentFooterItemField.PATH}`)}
                                                                onChange={setNewPath}
                                                            />
                                                            <Button
                                                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                                onClick={() => handleAddFooterItem(newLabel, newPath, locale)}
                                                                disabled={!newLabel.length || !newPath.length || !validateUrl(newPath)}
                                                            >
                                                                {textCommon(Action.ADD.labelKey)}
                                                            </Button>
                                                        </FieldBlock>
                                                        {!!portalSetting.content.footer.getItemsForLocale(locale)?.length &&
                                                            <Table
                                                                columns={[
                                                                    {width: 45, label: textPortalSettings(`field.${PortalSettingContentField.FOOTER}.${PortalSettingContentFooterField.ITEMS}.${PortalSettingContentFooterItemField.LABEL}`)},
                                                                    {width: 50, label: textPortalSettings(`field.${PortalSettingContentField.FOOTER}.${PortalSettingContentFooterField.ITEMS}.${PortalSettingContentFooterItemField.PATH}`)},
                                                                    {width: 5}
                                                                ]}
                                                            >
                                                                {portalSetting.content.footer.getItemsForLocale(locale)?.map((item) =>
                                                                    <TableRow key={item.label}>
                                                                        <TableColumn>{item.label}</TableColumn>
                                                                        <TableColumn>{item.path}</TableColumn>
                                                                        <TableActionColumn>
                                                                            <IconTooltip
                                                                                icon={Action.REMOVE.icon}
                                                                                text={textCommon(Action.REMOVE.labelKey)}
                                                                                onClick={() => handleRemoveFooterItem(item, locale)}
                                                                            />
                                                                        </TableActionColumn>
                                                                    </TableRow>
                                                                )}
                                                            </Table>
                                                        }
                                                    </FormLayoutRows>
                                                )}
                                            </Tabs>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutColumns>
                </LayoutRows>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="portal setting"
                    confirm={handleDeletePortalSetting}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default PortalSettingsDetails;
