import {Action, Alert, AlertSeverity, Box, BoxProps, CodeBlock, CodeBlockLanguage, ContentBlock, FieldBlock, FormLayoutRows, LayoutColumns, LayoutRows, Loadable, Table, TableColumn, TableRow, TagStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CustomerStorage} from "../../api/model/customer/storage/CustomerStorage";
import {CustomerStorageField} from "../../api/model/customer/storage/CustomerStorageField";
import {CustomerStorageUploadedFileField} from "../../api/model/customer/storage/CustomerStorageUploadedFileField";
import {Partner} from "../../api/model/partner/Partner";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ApiService} from "../../api/model/ApiService";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import ModalConfirmMessage from "../../component/modal/ModalConfirmMessage";
import {MainContentHeader, MainContentHeaderAction, SelectPartner, Tag} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";

function CustomerStoragesDetails() {
    const {t: textCustomerStorages} = useTranslation(TranslationPortalFile.CUSTOMER_STORAGES);
    const alert = useAlert();
    const {id} = useParams() as {id: string};

    const [isLoading, setLoading] = useState(true);
    const [storage, setStorage] = useState<CustomerStorage>(new CustomerStorage());
    const [partner, setPartner] = useState<Partner>();
    const [isShowModalConfirmRetryLoading, setShowModalConfirmRetryLoading] = useState(false);

    const [distinctMappingColumnCount, setDistinctMappingColumnCount] = useState(0);
    const [distinctMappingColumnMatchedCount, setDistinctMappingColumnMatchedCount] = useState(0);
    const MAX_STORAGE_OVERVIEW_ROWS = 5;

    useEffect(() => {
        (async function () {
            try {
                const result = await session.restCustomerStorage.get(id);
                setStorage(result);
                setDistinctMappingColumnCount(result.table.distinct_mapping_column_values);
                setDistinctMappingColumnMatchedCount(result.table_intersect?.segment?.distinct_mapping_column_values);

                const newPartner = await session.restPartner.get(result.partner_id);
                setPartner(newPartner);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("storage", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    const handleExecuteLoading = async () => {
        try {
            await session.restCustomerStorage.executeJob(storage);
            alert.actionWithSuccess("storage loading executed");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("execute storage loading", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CUSTOMER_STORAGES} element={storage.toContentElement()}>
                <RestrictedContent allowedTo={Authorization.CUSTOMER_STORAGES.update}>
                    {storage.canBeReloaded() &&
                        <MainContentHeaderAction
                            action={new Action(textCustomerStorages("action.retry_loading"), {name: "replay"})}
                            onClick={() => setShowModalConfirmRetryLoading(true)}
                        />
                    }
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <ContentBlock header={{title: {label: textCustomerStorages("section.information")}}}>
                            <Loadable loading={isLoading}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FieldBlock label={textCustomerStorages(`field.${CustomerStorageField.PARTNER_ID}`)}>
                                            <SelectPartner
                                                value={partner?.id}
                                                onChange={undefined}
                                                service={ApiService.CUSTOMER}
                                                disabled
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textCustomerStorages(`field.${CustomerStorageField.UPLOADED_FILE}.${CustomerStorageUploadedFileField.FILENAME}`)}>
                                            {storage.uploaded_file.filename.split("/").pop()}
                                        </FieldBlock>
                                        <FieldBlock label={textCustomerStorages(`field.${CustomerStorageField.EXPIRATION_TS}`)}>
                                            {Formatter.formatUTCDate(storage.expiration_ts, Formatter.DATETIME_FORMAT)}
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </Loadable>
                        </ContentBlock>
                        <ContentBlock header={{title: {label: textCustomerStorages("section.imported_data")}}}>
                            <Loadable loading={isLoading}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FieldBlock label={textCustomerStorages(`field.${CustomerStorageField.MAPPING_TYPE}`)} content={{noFullWidth: true}}>
                                            <Tag label={textCustomerStorages(`${CustomerStorageField.MAPPING_TYPE}.${storage.mapping_type}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                        </FieldBlock>
                                        <FieldBlock label={textCustomerStorages("field.distinct_mapping_column_matched")}>
                                            {`${Formatter.formatNumber(!!distinctMappingColumnMatchedCount ? distinctMappingColumnMatchedCount / distinctMappingColumnCount * 100 : 0)}% (${Formatter.formatNumber(distinctMappingColumnMatchedCount)} / ${Formatter.formatNumber(distinctMappingColumnCount)})`}
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </Loadable>
                        </ContentBlock>
                    </LayoutColumns>
                    {!!storage.error_code &&
                    <ContentBlock header={{title: {label: textCustomerStorages("section.error")}}}>
                        <Loadable loading={isLoading}>
                            <Alert text={textCustomerStorages("message.error_occurred")} severity={AlertSeverity.DANGER}/>
                            <CodeBlock
                                header={textCustomerStorages(`error_code.${storage.error_code}`)}
                                language={CodeBlockLanguage.JSON}
                                code={storage.error_message}
                                copiable
                                wrapLongLines
                            />
                        </Loadable>
                    </ContentBlock>
                    }
                    {!!storage.table?.row_sample &&
                    <ContentBlock header={{title: {label: textCustomerStorages("section.storage_overview")}}}>
                        <Loadable loading={isLoading}>
                            <Table columns={storage.table.column_names.map((columnName) => ({label: columnName}))}>
                                {storage.table.row_sample.slice(0, MAX_STORAGE_OVERVIEW_ROWS).map((row, rowIndex) => {
                                    const tableRowKey = `storage-overview-${rowIndex}`;
                                    return (
                                        <TableRow key={tableRowKey}>
                                            {row.map((value, index) => (
                                                <TableColumn key={`${tableRowKey}.${index.toString()}`}>{value}</TableColumn>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </Table>
                        </Loadable>
                    </ContentBlock>
                    }
                </LayoutRows>
                <ModalConfirmMessage
                    active={isShowModalConfirmRetryLoading}
                    message={textCustomerStorages("message.confirm_retry_loading")}
                    confirm={handleExecuteLoading}
                    confirmAction={textCustomerStorages("action.retry_loading")}
                    cancel={() => setShowModalConfirmRetryLoading(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CustomerStoragesDetails;
