import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    BoxProps,
    ContentBlock,
    ContentBlockAction,
    FieldBlock,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    InputDate,
    InputDateType,
    InputText,
    InputUrl,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    Table,
    TableColumnStyle,
    Tabs,
    Textarea
} from "@sirdata/ui-lib";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {SearchResult} from "../../api/interface/SearchResult";
import {Authorization} from "../../api/model/account/Authorization";
import {Media} from "../../api/model/media/Media";
import {Notification} from "../../api/model/notifications/Notification";
import {NotificationContent} from "../../api/model/notifications/NotificationContent";
import {NotificationDispatch} from "../../api/model/notifications/NotificationDispatch";
import {NotificationItem} from "../../api/model/notifications/NotificationItem";
import {NOTIFICATION_TYPES, NotificationType} from "../../api/model/notifications/NotificationType";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {Locale} from "../../common/utils/Locale";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalCreatePartnerDispatches from "../../component/modal/notifications/ModalCreatePartnerDispatches";
import ModalCreateUserDispatches from "../../component/modal/notifications/ModalCreateUserDispatches";
import ModalRescheduleDispatches from "../../component/modal/notifications/ModalRescheduleDispatches";
import ModalWarningUnsavedNotification from "../../component/modal/notifications/ModalWarningUnsavedNotification";
import {MainContentHeader, MainContentHeaderAction, MediaPicker, NotificationItemRow, SelectService} from "../../component/snippet";
import {PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {detectChanges} from "../../common/utils/portal";
import {NotificationField} from "../../api/model/notifications/NotificationField";
import {NotificationItemField} from "../../api/model/notifications/NotificationItemField";
import useAlert from "../../utils/hooks/useAlert";
import {NotificationContentField} from "../../api/model/notifications/NotificationContentField";
import {MediaType} from "../../api/model/media/MediaType";
import {TranslationCommonFile} from "../../common/utils/constants";
import {ApiService} from "../../api/model/ApiService";

enum NotificationRecipientType {
    USER,
    PARTNER
}

function NotificationsDetails() {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingDistribution, setLoadingDistribution] = useState(false);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);
    const [recipientType, setRecipientType] = useState<NotificationRecipientType>();
    const [isShowModalCreateDispatches, setShowModalCreateDispatches] = useState(false);
    const [isShowModalSendPendingDispatches, setShowModalSendPendingDispatches] = useState(false);
    const [isShowModalWarningUnsavedNotification, setShowModalWarningUnsavedNotification] = useState(false);

    const [notification, setNotification] = useState<Notification>(new Notification());
    const [initNotification, setInitNotification] = useState<Notification>(new Notification());
    const [userNotificationItems, setUserNotificationItems] = useState<NotificationItem[]>([]);
    const [userNotificationItemSearchResult, setUserNotificationItemSearchResult] = useState<SearchResult<NotificationItem>>(new SearchResult(NotificationItem));
    const [partnerAccountNotificationItems, setPartnerAccountNotificationItems] = useState<NotificationItem[]>([]);
    const [partnerAccountNotificationItemSearchResult, setPartnerAccountNotificationItemSearchResult] = useState<SearchResult<NotificationItem>>(new SearchResult(NotificationItem));

    const TEXT_MAX_LENGTH = 150;

    const refreshDistribution = useCallback(async () => {
        if (!notification.isDispatched()) return;
        setLoadingDistribution(true);

        try {
            switch (recipientType) {
                case NotificationRecipientType.USER:
                    const newUserNotificationItems = await session.restNotification.getUserItems(notification.id);
                    setUserNotificationItems(newUserNotificationItems);
                    setUserNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, newUserNotificationItems, 0, PAGE_SIZE));
                    break;
                case NotificationRecipientType.PARTNER:
                    const newPartnerAccountNotificationItems = await session.restNotification.getPartnerAccountItems(notification.id);
                    setPartnerAccountNotificationItems(newPartnerAccountNotificationItems);
                    setPartnerAccountNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, newPartnerAccountNotificationItems, 0, PAGE_SIZE));
                    break;
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("dispatches", e.message);
            }
        } finally {
            setLoadingDistribution(false);
        }
    }, [notification, recipientType, alert]);

    useEffect(() => {
        (async () => {
            try {
                const notification = await session.restNotification.get(+id);
                const newNotification = new Notification({
                    ...notification,
                    expires_at: notification.expires_at ? Formatter.convertUTCToDate(notification.expires_at).format(Formatter.API_DATETIME_FORMAT) : undefined
                });
                setNotification(newNotification);
                setInitNotification(new Notification(newNotification));

                const recipientType = notification.isInternal() ? NotificationRecipientType.USER : NotificationRecipientType.PARTNER;
                setRecipientType(recipientType);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("notification", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(notification, initNotification));
    }, [notification, initNotification]);

    useEffect(() => {
        (async () => {
            await refreshDistribution();
        })();
    }, [recipientType, refreshDistribution]);

    const handleChangeType = (type: NotificationType) => {
        const newNotification = new Notification({...notification, [NotificationField.TYPE]: type});
        if (newNotification.type === NotificationType.MODAL) {
            Locale.values().forEach((locale) => {
                newNotification.locales.set(locale, new NotificationContent({
                    ...notification?.locales.get(locale),
                    [NotificationContentField.CALL_TO_ACTION]: textNotifications(`default.call_to_action.${locale}`)
                }));
            });
        }
        setNotification(newNotification);
    };

    const handleChangeService = (service: string) => {
        const newNotification = new Notification({...notification, [NotificationField.SERVICE]: service});
        setRecipientType(newNotification.isInternal() ? NotificationRecipientType.USER : NotificationRecipientType.PARTNER);
        setNotification(newNotification);
    };

    const handleChangeLocale = (locale: Locale, field: NotificationContentField, value: any) => {
        const newNotification = new Notification(notification);
        const localeContent = new NotificationContent({...notification.locales.get(locale), [field]: value});
        newNotification.locales.set(locale, localeContent);
        setNotification(newNotification);
    };

    const handleSave = async () => {
        try {
            const newNotification = new Notification({
                ...notification,
                expires_at: notification.expires_at ? Formatter.convertDateToUTC(notification.expires_at) : undefined,
                clear_expires_at: !notification.expires_at
            });
            await session.restNotification.update(newNotification);
            setNotification(notification);
            setInitNotification(new Notification(notification));
            alert.updateWithSuccess("notification");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("notification", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restNotification.delete(notification.id);
            navigate(Module.NOTIFICATIONS.path);
            alert.deleteWithSuccess("notification");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("notification", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleReschedule = async (notificationDispatch: NotificationDispatch) => {
        setShowModalSendPendingDispatches(false);
        setLoadingDistribution(true);
        try {
            await session.restNotification.dispatch(notification.id, notificationDispatch);
            await refreshDistribution();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("reschedule dispatches", e.message);
            }
        } finally {
            setLoadingDistribution(false);
        }
    };

    const handleCreateDispatches = () => {
        if (isUnsavedChanges) {
            setShowModalWarningUnsavedNotification(true);
        } else {
            setShowModalCreateDispatches(true);
        }
    };

    const doCreateDispatches = async (notificationDispatch: NotificationDispatch, broadcast: boolean) => {
        setShowModalCreateDispatches(false);
        setLoadingDistribution(true);
        try {
            if (broadcast) {
                await session.restNotification.update(new Notification({
                    ...notification,
                    expires_at: notification.expires_at ? Formatter.convertDateToUTC(notification.expires_at) : undefined,
                    is_broadcast: true
                }));
                await session.restNotification.dispatch(notification.id, notificationDispatch);
            } else {
                switch (recipientType) {
                    case NotificationRecipientType.USER:
                        await session.restNotification.addUsers(notification.id, notificationDispatch);
                        break;
                    case NotificationRecipientType.PARTNER:
                        await session.restNotification.addPartners(notification.id, notificationDispatch);
                        break;
                }
            }
            const updatedNotification = await session.restNotification.get(notification.id);
            const newNotification = new Notification({
                ...updatedNotification,
                expires_at: updatedNotification.expires_at ? Formatter.convertUTCToDate(updatedNotification.expires_at).format(Formatter.API_DATETIME_FORMAT) : undefined
            });
            setNotification(newNotification);
            setInitNotification(new Notification(newNotification));
            await refreshDistribution();
            alert.createWithSuccess("dispatches");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("dispatches", e.message);
            }
        } finally {
            setLoadingDistribution(false);
        }
    };

    const handleRemoveUserDispatch = async (userId: number) => {
        try {
            await session.restNotification.removeUserItem(notification.id, userId);
            await refreshDistribution();
            alert.deleteWithSuccess("dispatch");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("dispatch", e.message);
            }
        }
    };

    const handleRemovePartnerAccountDispatch = async (partnerAccountId: number) => {
        try {
            await session.restNotification.removePartnerAccountItem(notification.id, partnerAccountId);
            await refreshDistribution();
            alert.deleteWithSuccess("dispatch");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("dispatch", e.message);
            }
        }
    };

    const handleChangeImage = (locale: Locale, media?: Media) => {
        const newNotification = new Notification(notification);
        const localeContent = new NotificationContent(notification.locales.get(locale));
        localeContent.image_id = media?.id;
        newNotification.locales.set(locale, localeContent);
        setNotification(newNotification);
    };

    const handleChangePage = (page: number) => {
        if (notification.isInternal()) {
            setUserNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, userNotificationItems, page - 1, PAGE_SIZE));
        } else {
            setPartnerAccountNotificationItemSearchResult(SearchResult.buildFromList(NotificationItem, partnerAccountNotificationItems, page - 1, PAGE_SIZE));
        }
    };

    const hasEmptyField = () => {
        if (!notification.name || !notification.expires_at) {
            return true;
        }

        const englishContent = notification.locales.get(Locale.ENGLISH);
        const frenchContent = notification.locales.get(Locale.FRENCH);
        if (notification.type === NotificationType.SLIDER) {
            return !frenchContent?.image_id || !englishContent?.image_id;
        } else if (notification.type === NotificationType.MODAL) {
            return !frenchContent?.image_id || !englishContent?.image_id;
        } else {
            return !frenchContent?.text || !englishContent?.text;
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.NOTIFICATIONS} element={initNotification.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.NOTIFICATIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={hasEmptyField() || !isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <LayoutRows>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textNotifications("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FieldBlock label={textNotifications(`field.${NotificationField.NAME}`)} required>
                                                <InputText
                                                    value={notification.name}
                                                    onChange={(value) => setNotification((prevState) => new Notification({...prevState, [NotificationField.NAME]: value}))}
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textNotifications(`field.${NotificationField.SERVICE}`)}>
                                                    <SelectService
                                                        value={notification.service}
                                                        services={ApiService.portals()}
                                                        onChange={(service) => handleChangeService(`${service?.name || ""}`)}
                                                        disabled={notification.isDispatched()}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textNotifications(`field.${NotificationField.TYPE}`)}>
                                                    <Select
                                                        value={notification.type}
                                                        options={NOTIFICATION_TYPES.map((it) => ({value: it, label: textNotifications(`type.${it}`)}))}
                                                        onChange={(option) => handleChangeType(option?.value as NotificationType)}
                                                        disabled={notification.isDispatched()}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock label={textNotifications(`field.${NotificationField.EXPIRES_AT}`)} required>
                                                    <InputDate
                                                        type={InputDateType.DATETIME_LOCAL}
                                                        value={notification.expires_at}
                                                        onChange={(value) => setNotification((prevState) => new Notification({...prevState, [NotificationField.EXPIRES_AT]: value}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textNotifications("section.content")}</FormLayoutTitle>
                                            <Alert text={textNotifications("message.content_mention")}/>
                                            <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                                                {Locale.values().map((locale) =>
                                                    <FormLayoutRows key={`${locale}`} spacing={FlexContentSpacing.MEDIUM}>
                                                        {[NotificationType.MODAL, NotificationType.SLIDER].includes(notification.type) &&
                                                            <>
                                                                <Alert text={textNotifications("message.image_mention")}/>
                                                                <FieldBlock label={textNotifications(`field.content.${NotificationContentField.IMAGE_ID}`)} required>
                                                                    <MediaPicker
                                                                        allowedType={MediaType.IMAGE}
                                                                        mediaId={notification.locales.get(locale)?.image_id}
                                                                        onSelect={(media) => handleChangeImage(locale, media)}
                                                                        onRemove={() => handleChangeImage(locale)}
                                                                    />
                                                                </FieldBlock>
                                                                <FormLayoutColumns>
                                                                    <FieldBlock label={textNotifications(`field.content.${NotificationContentField.TITLE}`)}>
                                                                        <InputText
                                                                            value={notification.locales.get(locale)?.title}
                                                                            onChange={(value) => handleChangeLocale(locale, NotificationContentField.TITLE, value)}
                                                                        />
                                                                    </FieldBlock>
                                                                    <FieldBlock label={textNotifications(`field.content.${NotificationContentField.SUBTITLE}`)}>
                                                                        <InputText
                                                                            value={notification.locales.get(locale)?.subtitle}
                                                                            onChange={(value) => handleChangeLocale(locale, NotificationContentField.SUBTITLE, value)}
                                                                        />
                                                                    </FieldBlock>
                                                                </FormLayoutColumns>
                                                            </>
                                                        }
                                                        <FieldBlock
                                                            label={textNotifications(`field.content.${NotificationContentField.TEXT}`)}
                                                            required={notification.type === NotificationType.DEFAULT}
                                                        >
                                                            <Textarea
                                                                value={notification.locales.get(locale)?.text}
                                                                rows={3}
                                                                maxLength={notification.type !== NotificationType.MODAL ? TEXT_MAX_LENGTH : undefined}
                                                                onChange={(value) => handleChangeLocale(locale, NotificationContentField.TEXT, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FormLayoutColumns>
                                                            <FieldBlock label={textNotifications(`field.content.${NotificationContentField.URL}`)}>
                                                                <InputUrl
                                                                    value={notification.locales.get(locale)?.url}
                                                                    onChange={(value) => handleChangeLocale(locale, NotificationContentField.URL, value)}
                                                                />
                                                            </FieldBlock>
                                                            {notification.type === NotificationType.MODAL &&
                                                                <FieldBlock label={textNotifications(`field.content.${NotificationContentField.CALL_TO_ACTION}`)}>
                                                                    <InputText
                                                                        value={notification.locales.get(locale)?.call_to_action !== undefined ? notification.locales.get(locale)?.call_to_action : textNotifications(`default.call_to_action.${locale}`)}
                                                                        onChange={(value) => handleChangeLocale(locale, NotificationContentField.CALL_TO_ACTION, value)}
                                                                    />
                                                                </FieldBlock>
                                                            }
                                                        </FormLayoutColumns>
                                                    </FormLayoutRows>
                                                )}
                                            </Tabs>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutRows>
                        <Loadable loading={isLoadingDistribution}>
                            {notification.isInternal() ?
                                <ContentBlock
                                    header={{
                                        title: {label: textNotifications("section.distribution")}, actions: [
                                            <RestrictedContent key="actions" allowedTo={Authorization.NOTIFICATIONS.update}>
                                                {!!userNotificationItems.filter(({isPending}) => isPending).length &&
                                                    <ContentBlockAction
                                                        action={new Action(textNotifications("actions.reschedule", {pendingCount: userNotificationItems.filter(({isPending}) => isPending).length}), {name: "update"})}
                                                        onClick={() => setShowModalSendPendingDispatches(true)}
                                                    />
                                                }
                                                {!notification.is_broadcast &&
                                                    <ContentBlockAction
                                                        action={Action.ADD}
                                                        onClick={handleCreateDispatches}
                                                    />
                                                }
                                            </RestrictedContent>
                                        ]
                                    }}
                                >
                                    {notification.is_broadcast &&
                                        <Alert text={textNotifications("message.broadcast_user")} severity={AlertSeverity.INFO}/>
                                    }
                                    <Table
                                        columns={[
                                            {width: 10},
                                            {width: 60, label: textNotifications(`field.${NotificationItemField.DISPATCH_DATE}`)},
                                            {width: 25, label: textNotifications(`field.${NotificationItemField.USER_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 5}
                                        ]}
                                        pagination={userNotificationItemSearchResult.getPagination(handleChangePage)}
                                    >
                                        {userNotificationItemSearchResult.elements.map((item) =>
                                            <NotificationItemRow key={item.id} item={item} onDelete={() => handleRemoveUserDispatch(item.user_id!)}/>
                                        )}
                                    </Table>
                                </ContentBlock> :
                                <ContentBlock
                                    header={{
                                        title: {label: textNotifications("section.distribution")}, actions: [
                                            <RestrictedContent key="actions" allowedTo={Authorization.NOTIFICATIONS.update}>
                                                {!!partnerAccountNotificationItems.filter(({isPending}) => isPending).length &&
                                                    <ContentBlockAction
                                                        action={new Action(textNotifications("actions.reschedule", {pendingCount: partnerAccountNotificationItems.filter(({isPending}) => isPending).length}), {name: "update"})}
                                                        onClick={() => setShowModalSendPendingDispatches(true)}
                                                    />
                                                }
                                                {!notification.is_broadcast &&
                                                    <ContentBlockAction action={Action.ADD} onClick={handleCreateDispatches}/>
                                                }
                                            </RestrictedContent>
                                        ]
                                    }}
                                >
                                    {notification.is_broadcast &&
                                        <Alert text={textNotifications("message.broadcast_partner")} severity={AlertSeverity.INFO}/>
                                    }
                                    <Table
                                        columns={[
                                            {width: 10},
                                            {width: 35, label: textNotifications(`field.${NotificationItemField.DISPATCH_DATE}`)},
                                            {width: 25, label: textNotifications(`field.${NotificationItemField.PARTNER_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 25, label: textNotifications(`field.${NotificationItemField.PARTNER_ACCOUNT_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 5}
                                        ]}
                                        pagination={partnerAccountNotificationItemSearchResult.getPagination(handleChangePage)}
                                    >
                                        {partnerAccountNotificationItemSearchResult.elements.map((item) =>
                                            <NotificationItemRow key={item.id} item={item} onDelete={() => handleRemovePartnerAccountDispatch(item.partner_account_id!)}/>
                                        )}
                                    </Table>
                                </ContentBlock>
                            }
                        </Loadable>
                    </LayoutColumns>
                </LayoutRows>
                {recipientType === NotificationRecipientType.PARTNER ?
                    <ModalCreatePartnerDispatches
                        active={isShowModalCreateDispatches}
                        onSubmit={doCreateDispatches}
                        onClose={() => setShowModalCreateDispatches(false)}
                    /> :
                    <ModalCreateUserDispatches
                        active={isShowModalCreateDispatches}
                        onSubmit={doCreateDispatches}
                        onClose={() => setShowModalCreateDispatches(false)}
                    />
                }
                <ModalRescheduleDispatches
                    active={isShowModalSendPendingDispatches}
                    onSubmit={handleReschedule}
                    onClose={() => setShowModalSendPendingDispatches(false)}
                />
                <ModalWarningUnsavedNotification
                    active={isShowModalWarningUnsavedNotification}
                    onClose={() => setShowModalWarningUnsavedNotification(false)}
                />
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="notification"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default NotificationsDetails;
