import {Action, ButtonLink, ContentBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentLayout, FormLayoutColumns, FormLayoutRows, Loadable, Table, TableColumnStyle, Textarea} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {DataleaksRegulation} from "../../api/model/dataleaks/DataleaksRegulation";
import {DataleaksSeverity} from "../../api/model/dataleaks/DataleaksSeverity";
import {DataleaksQuestion} from "../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksVersion} from "../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksVersionField} from "../../api/model/dataleaks/version/DataleaksVersionField";
import {DataleaksVersionQuestion} from "../../api/model/dataleaks/version/DataleaksVersionQuestion";
import {DataleaksVersionQuestionField} from "../../api/model/dataleaks/version/DataleaksVersionQuestionField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalDataleaksVersionAddQuestion from "../../component/modal/dataleaks/ModalDataleaksVersionAddQuestion";
import ModalCreateDataleaksVersion from "../../component/modal/dataleaks/ModalCreateDataleaksVersion";
import {DataleaksSeverityFlag, DataleaksVersionQuestionItem, DataleaksVersionRegulationWeights, DataleaksVersionTraitWeights, MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {DataleaksQuestionField} from "../../api/model/dataleaks/question/DataleaksQuestionField";
import {DataleaksQuestionTextsField} from "../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {detectChanges} from "../../common/utils/portal";
import {DataleaksVersionSeveritiesWeights} from "../../api/model/dataleaks/version/DataleaksVersionSeveritiesWeights";

function DataleaksVersionsDetails() {
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);
    const alert = useAlert();
    const {id} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreateDataleaksVersion, setShowModalCreateDataleaksVersion] = useState(false);
    const [isShowModalAddQuestion, setShowModalAddQuestion] = useState(false);
    const [version, setVersion] = useState<DataleaksVersion>(new DataleaksVersion());
    const [initVersion, setInitVersion] = useState<DataleaksVersion>(new DataleaksVersion());
    const [questions, setQuestions] = useState<DataleaksQuestion[]>([]);
    const [hasUnsavedChanged, setUnsavedChanges] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setQuestions(await session.restDataleaksQuestion.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("questions", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const newVersion = await session.restDataleaksVersion.get(+id);
                setVersion(newVersion);
                setInitVersion(new DataleaksVersion(newVersion));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("version", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(version, initVersion));
    }, [version, initVersion]);

    const handleChange = (field: DataleaksVersionField, value: any) => {
        setVersion((prevState) => new DataleaksVersion({...prevState, [field]: value}));
    };

    const handleChangeRegulationWeights = (regulation: DataleaksRegulation, severity: DataleaksSeverity, value: number) => {
        const newSeveritiesWeights = new DataleaksVersionSeveritiesWeights(version.getSeveritiesWeightsForRegulation(regulation));
        newSeveritiesWeights.setWeight(severity, value);
        const newRegulationWeights = new Map(version.regulation_weights);
        newRegulationWeights.set(regulation, newSeveritiesWeights);
        handleChange(DataleaksVersionField.REGULATION_WEIGHTS, newRegulationWeights);
    };

    const handleAddQuestion = (question: DataleaksQuestion) => {
        setShowModalAddQuestion(false);
        const newQuestion = new DataleaksVersionQuestion({
            [DataleaksVersionQuestionField.QUESTION_ID]: question.id,
            [DataleaksVersionQuestionField.INDEX]: Math.max(...version.questions.map((it) => it.index)) + 1
        });
        handleChange(DataleaksVersionField.QUESTIONS, [...version.questions, newQuestion]);
    };

    const handleChangeQuestion = (questionId: number, field: DataleaksVersionQuestionField, value: any) => {
        const newQuestions = version.questions.map((question) => {
            if (question.question_id === questionId) {
                return new DataleaksVersionQuestion({...question, [field]: value});
            }
            return question;
        });
        handleChange(DataleaksVersionField.QUESTIONS, newQuestions);
    };

    const buildSeveritiesDetails = () => {
        const mapSeverityCount = new Map<DataleaksSeverity, number>();
        Object.values(DataleaksSeverity).forEach((severity) => {
            mapSeverityCount.set(severity, version.questions.filter((it) => it.severity === severity).length);
        });
        return (
            <div className="dataleaks__severities">
                {Object.values(DataleaksSeverity).map((severity) =>
                    <div className="dataleaks__severities__item" key={severity}>
                        <DataleaksSeverityFlag severity={severity}/>
                        <span className="dataleaks__severities__item__label">{mapSeverityCount.get(severity)}</span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DATALEAKS_VERSIONS} element={version.toContentElement()}>
                <RestrictedContent allowedTo={Authorization.DATALEAKS.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={() => setShowModalCreateDataleaksVersion(true)} disabled={!hasUnsavedChanged}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Loadable loading={isLoading}>
                    <FormLayoutRows>
                        <ContentBlock
                            header={{
                                title: {label: textDataleaksVersions(`field.${DataleaksVersionField.QUESTIONS}.`)},
                                actions: buildSeveritiesDetails()
                            }}
                        >
                            <Table
                                columns={[
                                    {width: 5, label: textDataleaksVersions(`field.${DataleaksVersionField.QUESTIONS}.${DataleaksVersionQuestionField.INDEX}`), styles: TableColumnStyle.ALIGN_CENTER},
                                    {width: 70, label: textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TITLE}`)},
                                    {width: 10, label: textDataleaksQuestions(`field.${DataleaksQuestionField.TRAIT}`)},
                                    {width: 15, label: textDataleaksVersions(`field.${DataleaksVersionField.QUESTIONS}.${DataleaksVersionQuestionField.SEVERITY}`)}
                                ]}
                            >
                                {version.questions.map((item) =>
                                    <DataleaksVersionQuestionItem
                                        key={item.question_id}
                                        item={item}
                                        question={questions.find((it) => it.id === item.question_id) || new DataleaksQuestion()}
                                        onChange={(field, value) => handleChangeQuestion(item.question_id, field, value)}
                                    />
                                )}
                            </Table>
                            {
                                <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.END}>
                                    <ButtonLink
                                        icon={{name: "add_circle"}}
                                        onClick={() => setShowModalAddQuestion(true)}
                                        reverseUnderline
                                    >
                                        {textDataleaksVersions("add_question")}
                                    </ButtonLink>
                                </FlexContent>
                            }
                        </ContentBlock>
                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                            <DataleaksVersionRegulationWeights
                                version={version}
                                onChange={handleChangeRegulationWeights}
                            />
                            <DataleaksVersionTraitWeights
                                version={version}
                                questions={questions.filter((question) => version.questions.some((it) => question.id === it.question_id))}
                                onChange={(questionId, weight) => handleChangeQuestion(questionId, DataleaksVersionQuestionField.TRAIT_WEIGHT, weight)}
                            />
                        </FormLayoutColumns>
                        <ContentBlock header={{title: {label: textDataleaksVersions(`field.${DataleaksVersionField.COMMENT}`)}}}>
                            <Textarea
                                value={version.comment}
                                onChange={(value) => handleChange(DataleaksVersionField.COMMENT, value)}
                                rows={5}
                                disabled
                            />
                        </ContentBlock>
                    </FormLayoutRows>
                </Loadable>
            </MainContent>
            <ModalCreateDataleaksVersion
                active={isShowModalCreateDataleaksVersion}
                source={version}
                onClose={() => setShowModalCreateDataleaksVersion(false)}
            />
            <ModalDataleaksVersionAddQuestion
                active={isShowModalAddQuestion}
                availableQuestions={questions.filter((it) => !version.questions.some((question) => it.id === question.question_id))}
                onSubmit={handleAddQuestion}
                onClose={() => setShowModalAddQuestion(false)}
            />
        </Wrapper>
    );
}

export default DataleaksVersionsDetails;
