import {ButtonLinkCancel, ButtonValidate, Form, FormValidationType, ModalActions, ModalContent, ModalNew} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {session} from "../../../api/ApiSession";
import {SgtmContainer} from "../../../api/model/sgtm/container/SgtmContainer";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {SgtmContainerHostname} from "../../snippet";

export type ModalAddSgtmContainerHostProps = {
    active: boolean;
    container: SgtmContainer;
    onSubmit: (container: SgtmContainer) => void;
    onClose: () => void;
};

const ModalAddSgtmContainerHost: FunctionComponent<ModalAddSgtmContainerHostProps> = ({active, container, onSubmit, onClose}) => {
    const alert = useAlert();
    const [hostname, setHostname] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-add-sgtm-container-host";

    useEffect(() => {
        if (active) {
            setHostname("");
        }
    }, [active]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            container.instance_hosts.push(hostname);
            const updatedContainer = await session.restSgtmContainer.update(container);
            onSubmit(updatedContainer);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("add host", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <SgtmContainerHostname onChange={(hostname) => setHostname(hostname)}/>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddSgtmContainerHost;
