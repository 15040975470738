import {
    Action,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    FormLayoutRows,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {Partner} from "../../../api/model/partner/Partner";
import useAlert from "../../../utils/hooks/useAlert";
import {SelectPartner, TagPartnerRow} from "../../snippet";

type ModalAddPartnerToOrganizationProps = {
    active: boolean;
    organization: PartnerOrganization;
    onClose: (refresh: boolean) => void;
};

const ModalAddPartnerToOrganization: FunctionComponent<ModalAddPartnerToOrganizationProps> = ({active, organization, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const alert = useAlert();
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (active) {
            setSelectedPartners([]);
        }
    }, [active]);

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => {
            setHighlightedPartners([]);
        }, 1000);
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        for (let i = 0; i < selectedPartners.length; i++) {
            const partner = selectedPartners[i];
            try {
                await session.restPartnerOrganization.addPartner(organization.id, partner.id);
                alert.actionWithSuccess(`partner ${partner.fullName} added to organization`);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failTo(`add partner ${partner.fullName} to organization`, e.message);
                }
            }
        }
        setSubmitting(false);
        onClose(true);
    };

    const hasEmptyField = () => !selectedPartners.length;

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textOrganizations("modal.add_partners.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                    <SelectPartner
                        value={undefined}
                        onChange={(partner) => partner && handleAddPartners([partner])}
                        excludedPartners={selectedPartners}
                    />
                    <FieldBlock
                        label={textOrganizations("partners.selected", {count: selectedPartners.length})}
                        actions={!!selectedPartners.length ? <ButtonLink onClick={() => setSelectedPartners([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink> : []}
                    >
                        <ElementList placeholder={textOrganizations("partners.list_placeholder")} size={ElementListSize.BIG}>
                            {selectedPartners.map((partner) =>
                                <TagPartnerRow
                                    key={partner.id}
                                    partner={partner}
                                    isHighlighted={highlightedPartners?.some(({id}) => partner.id === id)}
                                    onRemove={() => setSelectedPartners(selectedPartners.filter(({id}) => partner.id !== id))}
                                />
                            )}
                        </ElementList>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddPartnerToOrganization;
