import {
    Action,
    Box,
    BoxProps,
    ContentBlock,
    ElementList,
    ElementListSize,
    FieldBlock,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    IconEdit,
    InputNumber,
    InputText,
    InputTextNumber,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    TagStyle,
    Textarea,
    ToggleSwitch
} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Category} from "../../api/model/audience/category/Category";
import {CategoryField} from "../../api/model/audience/category/CategoryField";
import {CategoryGroup} from "../../api/model/audience/category/CategoryGroup";
import {CategoryGroupField} from "../../api/model/audience/category/CategoryGroupField";
import {CategoryTaxonomy} from "../../api/model/audience/category/CategoryTaxonomy";
import {CategoryType} from "../../api/model/audience/category/CategoryType";
import {CurrencyType} from "../../api/model/currency/Currency";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {detectChanges} from "../../common/utils/portal";
import ModalImportCategoryPanel from "../../component/modal/categories/ModalImportCategoryPanel";
import ModalPopulateCategory from "../../component/modal/categories/ModalPopulateCategory";
import ModalConfirmMessage from "../../component/modal/ModalConfirmMessage";
import ModalEditTags from "../../component/modal/taxonomy-tags/ModalEditTags";
import {
    CategoryBQRequests,
    CategoryDomains,
    CategoryExternalLinks,
    CategoryGroupKeywordsLinks,
    CategoryGroupLinkedCategoryGroups,
    CategoryItem,
    CategorySegmentLinks,
    MainContentHeader,
    MainContentHeaderAction,
    SelectCategoryGroup,
    SelectStatus,
    Tag,
    TaxonomyPath
} from "../../component/snippet";
import {HandleSaveRef} from "../../utils/audience/HandleSaveRef";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";

export enum CategoriesDetailsSubModule {
    CATEGORY = "category",
    TAGS = "tags",
    LINKS_EXTERNAL = "links_external",
    LINKS_SEGMENTS = "links_segments",
    LINKS_KEYWORD = "links_keyword",
    BQ_REQUESTS = "bq_requests",
}

const CATEGORIES_DETAILS_SUB_MODULES: CategoriesDetailsSubModule[] = [
    CategoriesDetailsSubModule.CATEGORY,
    CategoriesDetailsSubModule.TAGS,
    CategoriesDetailsSubModule.LINKS_EXTERNAL,
    CategoriesDetailsSubModule.LINKS_SEGMENTS,
    CategoriesDetailsSubModule.LINKS_KEYWORD,
    CategoriesDetailsSubModule.BQ_REQUESTS
];

type CategoriesDetailsUnsavedChanges = {
    [CategoriesDetailsSubModule.CATEGORY]?: boolean;
    [CategoriesDetailsSubModule.TAGS]?: boolean;
    [CategoriesDetailsSubModule.LINKS_EXTERNAL]?: boolean;
    [CategoriesDetailsSubModule.LINKS_SEGMENTS]?: boolean;
    [CategoriesDetailsSubModule.LINKS_KEYWORD]?: boolean;
    [CategoriesDetailsSubModule.BQ_REQUESTS]?: boolean;
};

function CategoriesDetails() {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const {t: textTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const alert = useAlert();
    const {id} = useParams() as {id: string};
    const linksExternalRef = useRef<HandleSaveRef>(null);
    const linksSegmentRef = useRef<HandleSaveRef>(null);
    const linksKeywordRef = useRef<HandleSaveRef>(null);
    const bqRequestsRef = useRef<HandleSaveRef>(null);

    const [isLoading, setLoading] = useState(true);
    const [isLoadingCategoryType, setLoadingCategoryType] = useState(true);
    const [unsavedChanges, setUnsavedChanges] = useState<CategoriesDetailsUnsavedChanges>({});
    const [isShowModalConfirmStatusChange, setShowModalConfirmStatusChange] = useState(false);
    const [activeCreateCategoryType, setActiveCreateCategoryType] = useState<CategoryType>();

    const [categoryGroup, setCategoryGroup] = useState<CategoryGroup>(new CategoryGroup());
    const [initCategoryGroup, setInitCategoryGroup] = useState<CategoryGroup>(new CategoryGroup());
    const [currentCategory, setCurrentCategory] = useState<Category>(new Category());

    const [categoryGroupTags, setCategoryGroupTags] = useState<string[]>([]);
    const [initCategoryGroupTags, setInitCategoryGroupTags] = useState<string[]>([]);
    const [isShowModalEditTags, setShowModalEditTags] = useState(false);
    const [isShowModalImportCategoryPanel, setShowModalImportCategoryPanel] = useState(false);
    const [isPopulatingCategory, setPopulatingCategory] = useState(false);
    const [currencyRates, setCurrencyRates] = useState(new Map<string, number>());

    const maxValuePrice = 250;

    useEffect(() => {
        (async () => {
            try {
                const currencies = await session.restCurrency.list();
                setCurrencyRates(() => {
                    const currencyRates = new Map<string, number>();
                    for (const currency of currencies) {
                        currencyRates.set(currency.currency, currency.exchange_rate);
                    }
                    return currencyRates;
                });
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("currency rates", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async function () {
            try {
                const categoryGroup = await session.restCategoryGroup.get(+id);
                setCategoryGroup(categoryGroup);
                setInitCategoryGroup(new CategoryGroup(categoryGroup));
                setCurrentCategory(categoryGroup.cats[0]);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("category group", e.message);
                    }
                }
            } finally {
                setLoading(false);
                setLoadingCategoryType(false);
            }

            try {
                const account = await session.getAccount();
                if (account.hasAuthorization(Authorization.TAGS.name)) {
                    const linkTags = await session.restCategoryGroupLinkTag.list(+id);
                    const tags = linkTags.map((link) => link.tag_name);
                    setCategoryGroupTags(tags);
                    setInitCategoryGroupTags([...tags]);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("tags", e.message);
                }
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        if (!currentCategory) return;
        setCategoryGroup((prevState) => {
            const newState = new CategoryGroup({...prevState});
            newState.cats = newState.cats.map((it) => it.type === currentCategory.type ? currentCategory : it);
            return newState;
        });
    }, [currentCategory]);

    useEffect(() => {
        (async () => {
            try {
                const categoryGroups = await session.getCategoryGroups();
                const parent = categoryGroups.find((it) => it.id === categoryGroup.id_parent);
                if (parent) {
                    setCategoryGroup((prevState) => {
                        const newState = new CategoryGroup({...prevState});
                        newState.setTaxonomyPath(parent.code_name);
                        return newState;
                    });
                    setInitCategoryGroup((prevState) => {
                        const newState = new CategoryGroup({...prevState});
                        newState.setTaxonomyPath(parent.code_name);
                        return newState;
                    });
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("taxonomy path", e.message);
                }
            }
        })();
    }, [categoryGroup.id_parent, alert]);

    useEffect(() => {
        setUnsavedChanges((prevState) => ({
            ...prevState,
            [CategoriesDetailsSubModule.CATEGORY]: detectChanges(categoryGroup, initCategoryGroup),
            [CategoriesDetailsSubModule.TAGS]: detectChanges(categoryGroupTags, initCategoryGroupTags)
        }));
    }, [categoryGroup, initCategoryGroup, categoryGroupTags, initCategoryGroupTags]);

    const handleSave = async () => {
        if (unsavedChanges[CategoriesDetailsSubModule.CATEGORY]) {
            try {
                const updatedCategoryGroup = await session.restCategoryGroup.update(categoryGroup);
                setCategoryGroup(updatedCategoryGroup);
                setInitCategoryGroup(new CategoryGroup(updatedCategoryGroup));
                handleUnsavedChanges(CategoriesDetailsSubModule.CATEGORY, false);
                alert.updateWithSuccess("category");
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("category", e.message);
                }
            }
        }
        if (unsavedChanges[CategoriesDetailsSubModule.TAGS]) {
            try {
                await session.restCategoryGroupLinkTag.updateTagsForCategoryGroup(categoryGroup, categoryGroupTags);
                setInitCategoryGroupTags([...categoryGroupTags]);
                handleUnsavedChanges(CategoriesDetailsSubModule.TAGS, false);
                alert.updateWithSuccess("tags");
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("tags", e.message);
                }
            }
        }
        if (unsavedChanges[CategoriesDetailsSubModule.LINKS_EXTERNAL]) linksExternalRef.current?.handleSave();
        if (unsavedChanges[CategoriesDetailsSubModule.LINKS_SEGMENTS]) linksSegmentRef.current?.handleSave();
        if (unsavedChanges[CategoriesDetailsSubModule.LINKS_KEYWORD]) linksKeywordRef.current?.handleSave();
        if (unsavedChanges[CategoriesDetailsSubModule.BQ_REQUESTS]) bqRequestsRef.current?.handleSave();
    };

    const hasUnsavedChanges = () => {
        return CATEGORIES_DETAILS_SUB_MODULES.some((module) => unsavedChanges[module]);
    };

    const handleUnsavedChanges = useCallback((module: CategoriesDetailsSubModule, hasChanges: boolean) => {
        setUnsavedChanges((prevState) => ({...prevState, [module]: hasChanges}));
    }, []);

    const handleChangeLinksCategories = (linkedCategories: CategoryGroup[]) => {
        setCategoryGroup((prevState) => new CategoryGroup({...prevState, [CategoryGroupField.LINKS]: linkedCategories}));
    };

    const handleRemoveLinkCategory = (categoryGroup: CategoryGroup) => {
        setCategoryGroup((prevState) => new CategoryGroup({...prevState, [CategoryGroupField.LINKS]: prevState.links.filter((it) => it.id !== categoryGroup.id)}));
    };

    const handleChangeCategoryStatus = () => {
        setShowModalConfirmStatusChange(false);
        if (currentCategory.active && categoryGroup.cats.filter((it) => it.active).length === 1) {
            alert.failTo("change status", textCategories("message.warning_one_category_active"));
            return;
        }
        setCurrentCategory((prevState) => new Category({...prevState, [CategoryField.ACTIVE]: !prevState.active}));
    };

    const handleChangePrice = (value: number) => {
        const newCurrentCategory = new Category(currentCategory);
        newCurrentCategory.price = value;
        const rateUsd = currencyRates.get(CurrencyType.USD);
        if (rateUsd) {
            const usdPrice = Formatter.roundNumber(newCurrentCategory.price * rateUsd, 5);
            newCurrentCategory.price_usd = Math.min(usdPrice, maxValuePrice);
        }
        const rateGbp = currencyRates.get(CurrencyType.GBP);
        if (rateGbp) {
            const gbpPrice = Formatter.roundNumber(newCurrentCategory.price * rateGbp, 5);
            newCurrentCategory.price_gbp = Math.min(gbpPrice, maxValuePrice);
        }
        setCurrentCategory(newCurrentCategory);
    };

    const handleChangeTags = (tags: string[]) => {
        setCategoryGroupTags(tags);
        setShowModalEditTags(false);
    };

    const handleCopyName = () => {
        copy(`${categoryGroup.id} - ${categoryGroup.topTier} - ${currentCategory.type} - ${currentCategory.id} - ${categoryGroup.name}`);
        alert.copied("category info");
    };

    const handleSelectType = (type: CategoryType) => {
        if (categoryGroup.taxonomy === CategoryTaxonomy.BRAND_SAFETY.name) return;

        const category = categoryGroup.getCategory(type);
        if (category) {
            setLoadingCategoryType(true);
            setTimeout(() => {
                setCurrentCategory(category);
                setLoadingCategoryType(false);
            }, 500);
        } else {
            setActiveCreateCategoryType(type);
        }
    };

    const handleCreateCategoryType = async () => {
        if (!activeCreateCategoryType) return;
        try {
            const newCategory = new Category({id_group: categoryGroup.id, type: activeCreateCategoryType.name});
            const createdCategory = await session.restCategoryGroup.addCategoryType(newCategory);
            setCategoryGroup((prevState) => {
                const newState = new CategoryGroup({...prevState});
                newState.cats.push(createdCategory);
                return newState;
            });
            setLoadingCategoryType(true);
            setTimeout(() => {
                setCurrentCategory(createdCategory);
                setLoadingCategoryType(false);
            }, 500);
            alert.createWithSuccess("category");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("category", e.message);
            }
        } finally {
            setActiveCreateCategoryType(undefined);
        }
    };

    const handlePopulateCategory = async () => {
        try {
            setPopulatingCategory(true);
            await session.restModeling.populateCategories(currentCategory.id);
            alert.actionWithSuccess("category populated");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("populate category", e.message);
            }
        } finally {
            setPopulatingCategory(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={hasUnsavedChanges()}/>
            <MainContentHeader module={Module.CATEGORIES} element={initCategoryGroup.toContentElement()} preventUnsaved={hasUnsavedChanges()}>
                <MainContentHeaderAction
                    action={Action.COPY}
                    label={"Category Information"}
                    onClick={handleCopyName}
                />
                {currentCategory.type === CategoryType.MODELING.name ?
                    <RestrictedContent allowedTo={Authorization.MODELING.update}>
                        <MainContentHeaderAction
                            action={new Action(textCategories("action.import_panel"), {name: "user_attributes"})}
                            onClick={() => setShowModalImportCategoryPanel(true)}
                        />
                        <MainContentHeaderAction
                            action={new Action(textCategories("action.populate"), {name: "metabolism"})}
                            onClick={handlePopulateCategory}
                        />
                    </RestrictedContent> :
                    <></>
                }
                <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={categoryGroup.hasEmptyField() || !hasUnsavedChanges()}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock>
                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW} cssClass={"categories__types"}>
                                {CategoryType.values().map((item) =>
                                    <CategoryItem
                                        key={item.name}
                                        categoryGroup={categoryGroup}
                                        categoryType={item}
                                        onClick={handleSelectType}
                                        isSelected={currentCategory.type === item.name}
                                    />
                                )}
                            </Box>
                        </ContentBlock>
                    </Loadable>
                    <LayoutColumns>
                        <Loadable loading={isLoadingCategoryType}>
                            <ContentBlock header={{title: {label: textCategories("section.information")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        {categoryGroup.taxonomy !== CategoryTaxonomy.BRAND_SAFETY.name &&
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textCategories("field.category.status")}>
                                                    <SelectStatus
                                                        value={currentCategory.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                        statuses={Status.getActiveStatuses()}
                                                        onChange={() => setShowModalConfirmStatusChange(true)}
                                                    />
                                                </FieldBlock>
                                                {currentCategory.type === CategoryType.CONTEXTUAL.name &&
                                                    <FieldBlock name={CategoryField.PUBLIC} label={textCategories(`field.category.${CategoryField.PUBLIC}`)}>
                                                        <ToggleSwitch
                                                            checked={currentCategory.public}
                                                            onChange={(value) => setCurrentCategory((prevState) => new Category({...prevState, [CategoryField.PUBLIC]: value}))}
                                                        />
                                                    </FieldBlock>
                                                }
                                            </FormLayoutColumns>
                                        }
                                        <FormLayoutColumns>
                                            <FieldBlock label={textCategories(`field.${CategoryGroupField.TAXONOMY}`)}>
                                                <Select
                                                    value={categoryGroup.taxonomy}
                                                    options={CategoryTaxonomy.values().filter((it) => it.name !== CategoryTaxonomy.PARTNER.name).map((it) => ({value: it.name, label: textCategories(`taxonomy.${it.name}`)}))}
                                                    onChange={() => {}}
                                                    disabled
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textCategories(`field.${CategoryGroupField.ID_EXTERNAL}`)}>
                                                <InputNumber
                                                    value={categoryGroup.id_ext}
                                                    onChange={(value) => setCategoryGroup((prevState) => new CategoryGroup({...prevState, [CategoryGroupField.ID_EXTERNAL]: value}))}
                                                    disabled={categoryGroup.taxonomy !== CategoryTaxonomy.IAB.name}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FieldBlock label={textCategories(`field.${CategoryGroupField.ID_PARENT}`)}>
                                            <SelectCategoryGroup
                                                value={categoryGroup.id_parent}
                                                onChange={(parent) => setCategoryGroup((prevState) => new CategoryGroup({...prevState, [CategoryGroupField.ID_PARENT]: parent?.id}))}
                                                taxonomy={CategoryTaxonomy.getByName(categoryGroup.taxonomy)}
                                            />
                                        </FieldBlock>
                                        {categoryGroup.id_parent &&
                                            <FieldBlock label={textCategories(`field.${CategoryGroupField.CODE_NAME}`)}>
                                                <TaxonomyPath items={categoryGroup.taxonomyPath}/>
                                            </FieldBlock>
                                        }
                                        <FieldBlock label={textCategories(`field.${CategoryGroupField.NAME}`)} required>
                                            <InputText
                                                value={categoryGroup.name}
                                                onChange={(value) => setCategoryGroup((prevState) => new CategoryGroup({...prevState, [CategoryGroupField.NAME]: value}))}
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textCategories(`field.${CategoryGroupField.DESCRIPTION}`)}>
                                            <Textarea
                                                value={categoryGroup.description}
                                                onChange={(value) => setCategoryGroup((prevState) => new CategoryGroup({...prevState, [CategoryGroupField.DESCRIPTION]: value}))}
                                                rows={3}
                                            />
                                        </FieldBlock>
                                        <FormLayoutSeparator/>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textCategories(`field.category.${CategoryField.PRICE}`)}>
                                                <InputTextNumber
                                                    value={Formatter.convertCentsToUnits(currentCategory.price)}
                                                    minValue={0}
                                                    maxValue={Formatter.convertCentsToUnits(maxValuePrice)}
                                                    onChange={(value) => handleChangePrice(Formatter.convertUnitsToCents(value))}
                                                />
                                                <span>{textCategories("field.currency.eur")}</span>
                                            </FieldBlock>
                                            <FieldBlock label={textCategories(`field.category.${CategoryField.PRICE_USD}`)}>
                                                <InputTextNumber
                                                    value={Formatter.convertCentsToUnits(currentCategory.price_usd)}
                                                    minValue={0}
                                                    maxValue={Formatter.convertCentsToUnits(maxValuePrice)}
                                                    onChange={(value) => handleChangePrice(Formatter.convertUnitsToCents(value))}
                                                    disabled
                                                />
                                                <span>{textCategories("field.currency.usd")}</span>
                                            </FieldBlock>
                                            <FieldBlock label={textCategories(`field.category.${CategoryField.PRICE_GBP}`)}>
                                                <InputTextNumber
                                                    value={Formatter.convertCentsToUnits(currentCategory.price_gbp)}
                                                    minValue={0}
                                                    maxValue={Formatter.convertCentsToUnits(maxValuePrice)}
                                                    onChange={(value) => handleChangePrice(Formatter.convertUnitsToCents(value))}
                                                    disabled
                                                />
                                                <span>{textCategories("field.currency.gbp")}</span>
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <RestrictedContent allowedTo={Authorization.TAGS.name}>
                                            <FormLayoutSeparator/>
                                            <FieldBlock
                                                label={textCategories("section.tags")}
                                                actions={
                                                    <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                                                        <IconEdit onClick={() => setShowModalEditTags(true)}/>
                                                    </RestrictedContent>
                                                }
                                            >
                                                <ElementList placeholder={textTags("placeholder.no_tag_selected")} size={ElementListSize.SMALL} inline>
                                                    {categoryGroupTags.map((tag) =>
                                                        <Tag key={tag} label={tag} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                                    )}
                                                </ElementList>
                                            </FieldBlock>
                                        </RestrictedContent>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                        <LayoutRows>
                            <Loadable loading={isLoadingCategoryType}>
                                {categoryGroup.taxonomy !== CategoryTaxonomy.BRAND_SAFETY.name &&
                                    <CategoryGroupLinkedCategoryGroups
                                        links={categoryGroup.links}
                                        taxonomy={CategoryTaxonomy.getByName(categoryGroup.taxonomy)}
                                        onChange={handleChangeLinksCategories}
                                        onRemove={handleRemoveLinkCategory}
                                    />
                                }
                                {[CategoryType.INTENT, CategoryType.INTEREST, CategoryType.DECLARATIVE, CategoryType.MODELING].map((it) => it.name).includes(currentCategory.type) &&
                                    <CategorySegmentLinks
                                        category={currentCategory}
                                        handleSaveRef={linksSegmentRef}
                                        onChange={handleUnsavedChanges}
                                    />
                                }
                                {[CategoryType.CONTEXTUAL, CategoryType.BRAND_SAFETY].map((it) => it.name).includes(currentCategory.type) &&
                                    <CategoryExternalLinks
                                        category={currentCategory}
                                        handleSaveRef={linksExternalRef}
                                        onChange={handleUnsavedChanges}
                                    />
                                }
                                {[CategoryType.INTENT, CategoryType.INTEREST, CategoryType.DECLARATIVE].map((it) => it.name).includes(currentCategory.type) &&
                                    <CategoryBQRequests
                                        category={currentCategory}
                                        categoryName={categoryGroup.code_name}
                                        handleSaveRef={bqRequestsRef}
                                        onChange={handleUnsavedChanges}
                                    />
                                }
                            </Loadable>
                        </LayoutRows>
                    </LayoutColumns>
                    <RestrictedContent allowedTo={Authorization.KEYWORDS.name}>
                        <Loadable loading={isLoadingCategoryType}>
                            {[CategoryType.INTENT, CategoryType.INTEREST, CategoryType.CONTEXTUAL, CategoryType.BRAND_SAFETY].map((it) => it.name).includes(currentCategory.type) &&
                                <CategoryGroupKeywordsLinks
                                    categoryGroupId={categoryGroup.id}
                                    handleSaveRef={linksKeywordRef}
                                    onChange={handleUnsavedChanges}
                                />
                            }
                        </Loadable>
                    </RestrictedContent>
                    {currentCategory.type === CategoryType.MODELING.name &&
                        <Loadable loading={isLoadingCategoryType}>
                            <CategoryDomains category={currentCategory}/>
                        </Loadable>
                    }
                </LayoutRows>
                <ModalConfirmMessage
                    active={!!activeCreateCategoryType}
                    message={textCategories("message.confirm_activation_type", {type: textCategories(`category_type.${activeCreateCategoryType?.name}`)})}
                    confirm={handleCreateCategoryType}
                    confirmAction={Action.ACTIVATE.labelKey}
                    cancel={() => setActiveCreateCategoryType(undefined)}
                />
                <ModalConfirmMessage
                    active={isShowModalConfirmStatusChange}
                    message={textCategories(currentCategory.active ? "message.confirm_deactivation" : "message.confirm_activation")}
                    confirm={handleChangeCategoryStatus}
                    confirmAction={currentCategory.active ? Action.DEACTIVATE.labelKey : Action.ACTIVATE.labelKey}
                    cancel={() => setShowModalConfirmStatusChange(false)}
                />
                <ModalEditTags
                    active={isShowModalEditTags}
                    initTags={categoryGroupTags}
                    onSubmit={handleChangeTags}
                    onClose={() => setShowModalEditTags(false)}
                />
                <ModalImportCategoryPanel
                    active={isShowModalImportCategoryPanel}
                    onClose={() => setShowModalImportCategoryPanel(false)}
                    category={currentCategory}
                />
                <ModalPopulateCategory active={isPopulatingCategory}/>
            </MainContent>
        </Wrapper>
    );
}

export default CategoriesDetails;
