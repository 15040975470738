import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {session} from "../../api/ApiSession";
import {CmpWebinar} from "../../api/model/cmp/webinar/CmpWebinar";
import {CmpWebinarFaqItem, CmpWebinarFaqItemField} from "../../api/model/cmp/webinar/CmpWebinarFaqItem";
import {CmpWebinarInfo} from "../../api/model/cmp/webinar/CmpWebinarInfo";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {Locale} from "../../common/utils/Locale";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {
    Action,
    Alert,
    Box,
    BoxProps,
    BoxRadius,
    ButtonAdd,
    ButtonStyle,
    ContentBlock,
    FieldBlock,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    GoToTop,
    InputDate,
    InputDateType,
    InputText,
    LayoutRows,
    Loadable,
    Tabs,
    Textarea
} from "@sirdata/ui-lib";
import {IframeBlock, MainContentHeader, MainContentHeaderAction, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {Formatter} from "../../common/utils/Formatter";
import {Authorization} from "../../api/model/account/Authorization";
import {MainHeader} from "../../common/component/snippet";
import {TranslationCommonFile} from "../../common/utils/constants";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {Status} from "../../utils/Status";
import {detectChanges} from "../../common/utils/portal";
import {CmpWebinarField} from "../../api/model/cmp/webinar/CmpWebinarField";
import {CmpWebinarInfoField} from "../../api/model/cmp/webinar/CmpWebinarInfoField";
import useAlert from "../../utils/hooks/useAlert";
import {CMP_WEBINAR_STATUSES} from "../../api/model/cmp/webinar/CmpWebinarStatus";

function CmpWebinarsDetails() {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textCmpWebinars} = useTranslation(TranslationPortalFile.CMP_WEBINARS);
    const {id} = useParams() as {id: string};
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isShowGoToTop, setShowGoToTop] = useState(false);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);

    const [webinar, setWebinar] = useState<CmpWebinar>(new CmpWebinar());
    const [initWebinar, setInitWebinar] = useState<CmpWebinar>(new CmpWebinar());

    useEffect(() => {
        (async function () {
            try {
                const webinar = await session.restCmpWebinar.get(id);
                const newWebinar = new CmpWebinar({
                    ...webinar,
                    date: webinar.date ? Formatter.formatDate(webinar.date, Formatter.API_DATE_FORMAT) : "",
                    info: new Map(webinar.info),
                    faq: new Map()
                });
                webinar.faq.forEach((values, key) => {
                    newWebinar.faq = new Map(newWebinar.faq.set(key, values.map((it) => new CmpWebinarFaqItem(it))));
                });
                setInitWebinar(new CmpWebinar(newWebinar));
                setWebinar(newWebinar);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("webinar", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(webinar, initWebinar));
    }, [webinar, initWebinar]);

    useEffect(() => {
        function toggleGoToTop() {
            if (isShowGoToTop && window.scrollY < 150)
                setShowGoToTop(false);
            else if (!isShowGoToTop && window.scrollY > 150)
                setShowGoToTop(true);
        }

        window.addEventListener("scroll", toggleGoToTop, {passive: true});
        return () => window.removeEventListener("scroll", toggleGoToTop);
    });

    const addWebinarFaq = (locale: Locale) => {
        let newWebinarFaq = new Map<Locale, CmpWebinarFaqItem[]>(webinar.faq);

        let newWebinarFaqItems = newWebinarFaq.get(locale) || [];
        newWebinarFaqItems = [...newWebinarFaqItems, new CmpWebinarFaqItem()];

        newWebinarFaq.set(locale, newWebinarFaqItems);

        const newCurrentWebinar = new CmpWebinar({...webinar, faq: new Map(newWebinarFaq)});
        setWebinar(newCurrentWebinar);
        scrollToBottom();
    };

    const hasEmptyField = () => {
        return !webinar.date || !!Locale.values().find((locale) => webinar.getInfoForLanguage(locale).hasEmptyField());
    };

    const handleSave = async () => {
        try {
            webinar.date = webinar.date ? Formatter.formatDate(webinar.date, Formatter.API_DATE_FORMAT) : "";

            const result = await session.restCmpWebinar.update(webinar);
            const newWebinar = new CmpWebinar({
                ...result,
                date: result.date ? Formatter.formatDate(result.date, Formatter.API_DATE_FORMAT) : "",
                info: new Map(result.info),
                faq: new Map()
            });
            setWebinar(newWebinar);
            setInitWebinar(new CmpWebinar(newWebinar));
            alert.updateWithSuccess("webinar");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("webinar", e.message);
            }
        }
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    };

    const handleChangeFaq = (locale: Locale, faqIndex: number, field: CmpWebinarFaqItemField, value: string) => {
        let newCurrentWebinar = new CmpWebinar(webinar);

        let newWebinarFaqs = newCurrentWebinar.getFaqForLanguage(locale);
        newWebinarFaqs[faqIndex][field] = value;

        newCurrentWebinar.faq.set(locale, newWebinarFaqs);
        setWebinar(newCurrentWebinar);
    };

    const handleChangeWebinarInfo = (locale: Locale, field: CmpWebinarInfoField, value: any) => {
        const newCurrentWebinar = new CmpWebinar(webinar);

        const info = new CmpWebinarInfo({...newCurrentWebinar.info.get(locale), [field]: value});
        newCurrentWebinar.info.set(locale, info);

        setWebinar(newCurrentWebinar);
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.CMP_WEBINARS} element={initWebinar.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.CMP_WEBINARS.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={!isUnsavedChanges || hasEmptyField()}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock>
                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                <FormLayoutRows>
                                    <FormLayoutColumns columns={4}>
                                        <FormLayoutColumns columns={2}>
                                            <FieldBlock label={textCmpWebinars(`field.${CmpWebinarField.STATUS}`)}>
                                                <SelectStatus
                                                    value={webinar.status}
                                                    statuses={CMP_WEBINAR_STATUSES.filter((it) => it.name !== Status.DELETED.name)}
                                                    onChange={(status) => setWebinar(new CmpWebinar({...webinar, [CmpWebinarField.STATUS]: status!.name}))}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textCmpWebinars(`field.${CmpWebinarField.DATE}`)} required>
                                                <InputDate
                                                    type={InputDateType.DATE}
                                                    value={webinar.date}
                                                    onChange={(value) => setWebinar(new CmpWebinar({...webinar, [CmpWebinarField.DATE]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                    </FormLayoutColumns>
                                    <FormLayoutSeparator/>
                                    <Alert text={textCmpWebinars("message.content_mention")}/>
                                    <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                                        {Locale.values().map((locale) =>
                                            <FormLayoutColumns key={`${locale}`}>
                                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                    <FieldBlock label={textCmpWebinars(`field.${CmpWebinarInfoField.TITLE}`)} required>
                                                        <InputText
                                                            value={webinar.getInfoForLanguage(locale)?.title}
                                                            onChange={(value) => handleChangeWebinarInfo(locale, CmpWebinarInfoField.TITLE, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textCmpWebinars(`field.${CmpWebinarInfoField.DESCRIPTION}`)} required>
                                                        <Textarea
                                                            value={webinar.getInfoForLanguage(locale)?.description}
                                                            rows={10}
                                                            onChange={(value) => handleChangeWebinarInfo(locale, CmpWebinarInfoField.DESCRIPTION, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                                                        <FieldBlock
                                                            label={textCmpWebinars(`field.${CmpWebinarInfoField.YOUTUBE_ID}`)}
                                                            required
                                                        >
                                                            <InputText
                                                                value={webinar.getInfoForLanguage(locale)?.youtube_id}
                                                                onChange={(value) => handleChangeWebinarInfo(locale, CmpWebinarInfoField.YOUTUBE_ID, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FieldBlock label={textCmpWebinars(`field.${CmpWebinarInfoField.COVER_URL}`)}>
                                                            <InputText
                                                                value={webinar.getInfoForLanguage(locale)?.cover_url}
                                                                onChange={(value) => handleChangeWebinarInfo(locale, CmpWebinarInfoField.COVER_URL, value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutColumns>
                                                    <FieldBlock label={textCmpWebinars(`field.${CmpWebinarInfoField.ANNOUNCEMENT}`)}>
                                                        <InputText
                                                            value={webinar.getInfoForLanguage(locale)?.announcement}
                                                            onChange={(value) => handleChangeWebinarInfo(locale, CmpWebinarInfoField.ANNOUNCEMENT, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FormLayoutSeparator/>
                                                    <ContentBlock
                                                        header={{
                                                            title: {label: textCmpWebinars("field.questions_answers")},
                                                            actions: (
                                                                <ButtonAdd
                                                                    onClick={() => addWebinarFaq(locale)}
                                                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                                />
                                                            )
                                                        }}
                                                    >
                                                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                            {webinar.getFaqForLanguage(locale).map((faqItem, index) =>
                                                                <FieldBlock
                                                                    key={`question-${index.toString()}`}
                                                                    label={`${textCmpWebinars("field.question")} ${index + 1}`}
                                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                                >
                                                                    <Textarea
                                                                        placeholder={textCmpWebinars("field.write_question_in_language", {language: textMain(`language.${locale}`)})}
                                                                        value={faqItem.question}
                                                                        rows={3}
                                                                        onChange={(value) => handleChangeFaq(locale, index, CmpWebinarFaqItemField.QUESTION, value)}
                                                                    />
                                                                    <Textarea
                                                                        placeholder={textCmpWebinars("field.write_answer_in_language", {language: textMain(`language.${locale}`)})}
                                                                        value={faqItem.answer}
                                                                        rows={3}
                                                                        onChange={(value) => handleChangeFaq(locale, index, CmpWebinarFaqItemField.ANSWER, value)}
                                                                    />
                                                                </FieldBlock>
                                                            )}
                                                        </FormLayoutRows>
                                                    </ContentBlock>
                                                </FormLayoutRows>
                                                <FormLayoutRows>
                                                    <IframeBlock
                                                        radius={BoxRadius.MD}
                                                        src={"https://www.youtube.com/embed/" + webinar.getInfoForLanguage(locale)?.youtube_id || ""}
                                                        title="webinar-iframe"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    />
                                                </FormLayoutRows>
                                            </FormLayoutColumns>
                                        )}
                                    </Tabs>
                                </FormLayoutRows>
                            </Box>
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
                {isShowGoToTop && <GoToTop/>}
            </MainContent>
        </Wrapper>
    );
}

export default CmpWebinarsDetails;
