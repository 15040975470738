import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Deal} from "../../../api/model/deal/Deal";
import {DealField} from "../../../api/model/deal/DealField";
import {DealTiers} from "../../../api/model/deal/DealTiers";
import {DealTiersField} from "../../../api/model/deal/DealTiersField";
import {DealTopTier} from "../../../api/model/deal/DealTopTier";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectStatus} from "../../snippet";
import {Status} from "../../../utils/Status";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";

export type ModalCreateDealProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateDeal: FunctionComponent<ModalCreateDealProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textDeals} = useTranslation(TranslationPortalFile.DEALS);
    const navigate = useNavigate();
    const [deal, setDeal] = useState<Deal>(new Deal());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-deal";

    useEffect(() => {
        if (active) {
            setDeal(new Deal({[DealField.TIERS]: new DealTiers()}));
        }
    }, [active]);

    const handleChange = (field: DealField, value: any) => {
        setDeal((prevState) => new Deal({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newDeal = await session.restDeal.create(deal);
            navigate(newDeal.getRoute());
            onClose();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("deal", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textDeals("create_deal")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textDeals("field.status")}>
                                <SelectStatus
                                    value={deal.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(DealField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textDeals(`field.${DealField.NAME}`)} required>
                                <InputText
                                    value={deal.name}
                                    onChange={(value) => handleChange(DealField.NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textDeals(`field.${DealField.TIERS}.${DealTiersField.TIERS1}`)} required>
                                <Select
                                    value={deal.tiers.tiers1}
                                    options={DealTopTier.values().map((it) => ({label: it.name, value: it.name}))}
                                    onChange={(option) => handleChange(DealField.TIERS, new DealTiers({...deal.tiers, [DealTiersField.TIERS1]: option?.value}))}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textDeals(`field.${DealField.DESCRIPTION}`)} required>
                            <InputText
                                value={deal.description}
                                onChange={(value) => handleChange(DealField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateDeal;
