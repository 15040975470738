import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, InputTextNumber, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, ToggleSwitch} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerPricing} from "../../../api/model/partner/pricing/PartnerPricing";
import {PartnerPricingField} from "../../../api/model/partner/pricing/PartnerPricingField";
import useAlert from "../../../utils/hooks/useAlert";
import {SelectPartner} from "../../snippet";
import {Formatter} from "../../../common/utils/Formatter";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";

export type ModalCreatePartnerPricingProps = {
    active: boolean;
    pricingId: number;
    onClose: (refresh: boolean) => void;
};

const ModalCreatePartnerPricing: FunctionComponent<ModalCreatePartnerPricingProps> = ({active, pricingId, onClose}) => {
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const [partnerPricing, setPartnerPricing] = useState<PartnerPricing>(new PartnerPricing());
    const alert = useAlert();
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-partner-pricing";

    useEffect(() => {
        if (active) {
            const newPartnerPricing = new PartnerPricing();
            newPartnerPricing.id_pricing = pricingId;
            setPartnerPricing(newPartnerPricing);
        }
    }, [active, pricingId]);

    const handleChange = (field: PartnerPricingField, value: any) => {
        setPartnerPricing((prevState) => new PartnerPricing({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            partnerPricing.start_date = partnerPricing.start_date ? Formatter.formatDate(partnerPricing.start_date, Formatter.API_DATE_FORMAT) : "";
            partnerPricing.end_date = partnerPricing.end_date ? Formatter.formatDate(partnerPricing.end_date, Formatter.API_DATE_FORMAT) : "";
            await session.restPartnerPricing.create(partnerPricing);
            alert.createWithSuccess("rate card setting for partner");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("rate card setting for partner", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textPricings("create_partner_pricing")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.ID_PARTNER}`)} required>
                            <SelectPartner
                                value={partnerPricing.id_partner}
                                onChange={(partner) => handleChange(PartnerPricingField.ID_PARTNER, partner?.id || 0)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.START_DATE}`)} required>
                                <InputDate
                                    type={InputDateType.DATE}
                                    value={partnerPricing.start_date}
                                    onChange={(value) => handleChange(PartnerPricingField.START_DATE, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.END_DATE}`)}>
                                <InputDate
                                    type={InputDateType.DATE}
                                    value={partnerPricing.end_date}
                                    onChange={(value) => handleChange(PartnerPricingField.END_DATE, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.DISCOUNT}`)}>
                                <InputTextNumber
                                    value={partnerPricing.discount}
                                    minValue={0}
                                    maxValue={100}
                                    onChange={(value) => handleChange(PartnerPricingField.DISCOUNT, value)}
                                />
                                <span>%</span>
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock name={PartnerPricingField.ACTIVE}>
                            <ToggleSwitch
                                label={textPricings(`field.partner.${PartnerPricingField.ACTIVE}`)}
                                checked={partnerPricing.active}
                                onChange={(value) => handleChange(PartnerPricingField.ACTIVE, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePartnerPricing;
