import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {defaultMultiPlatform, RevenuePlatform} from "../../../api/model/revenue/RevenuePlatform";
import {
    Action,
    AlertSeverity,
    Button,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputDate,
    InputDateType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    ToggleSwitch,
    UploadPanel
} from "@sirdata/ui-lib";
import {RevenuePlatformSource} from "../../../api/model/revenue/RevenuePlatformSource";
import {RevenueImportRequest} from "../../../api/model/revenue/import/RevenueImportRequest";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {downloadCSV} from "../../../common/utils/portal";
import useAlert from "../../../utils/hooks/useAlert";
import {RevenuePeriodType} from "../../../api/model/revenue/RevenuePeriodType";
import {RevenueImportRequestField} from "../../../api/model/revenue/import/RevenueImportRequestField";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {Formatter} from "../../../common/utils/Formatter";
import moment from "moment";
import useFormValidator from "../../../utils/hooks/useFormValidator";

type ModalImportRevenuesProps = {
    active: boolean;
    onClose: (refresh: boolean) => void;
}

const ModalImportRevenues: FunctionComponent<ModalImportRevenuesProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textRevenuesImports} = useTranslation(TranslationPortalFile.REVENUES_IMPORTS);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isLoadingImport, setLoadingImport] = useState<boolean>(false);
    const [platforms, setPlatforms] = useState<RevenuePlatform[]>([]);
    const [periodTypes, setPeriodTypes] = useState<RevenuePeriodType[]>(RevenuePeriodType.values().filter((it) => !it.isInterval));
    const [fileName, setFileName] = useState<string>("");
    const [revenueImportRequest, setRevenueImportRequest] = useState<RevenueImportRequest>(new RevenueImportRequest());
    const FORM_ID = "modal-import-revenues";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<RevenueImportRequestField>();

    useEffect(() => {
        if (active) {
            if (!!platforms.length) {
                const platform = platforms[0];
                setRevenueImportRequest(new RevenueImportRequest({[RevenueImportRequestField.PLATFORM]: platform}));
            }
            setShowErrors(false);
        }
    }, [active, platforms, setShowErrors]);

    useEffect(() => {
        (async () => {
            try {
                let platforms = await session.restRevenuesImport.getPlatforms();
                platforms = platforms.filter(({revenue_format, source}) => (revenue_format && source) || source === RevenuePlatformSource.API);
                platforms.push(defaultMultiPlatform);
                setPlatforms(platforms);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("platforms", e.message);
                }
                setPlatforms([defaultMultiPlatform]);
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (revenueImportRequest.platform.source === RevenuePlatformSource.API) {
            setPeriodTypes(RevenuePeriodType.values());
        } else {
            setPeriodTypes(RevenuePeriodType.values().filter((it) => !it.isInterval));
        }
        setFileName("");
    }, [revenueImportRequest.platform]);

    useEffect(() => {
        setErrors((prevState) => ({...prevState, [RevenueImportRequestField.FILE_BUFFER]: revenueImportRequest.platform.source === RevenuePlatformSource.FILE && !revenueImportRequest.file_buffer}));
    }, [setErrors, revenueImportRequest]);

    const handleChange = (field: RevenueImportRequestField, value: any) => {
        setRevenueImportRequest((prevState) => new RevenueImportRequest({...prevState, [field]: value}));
    };

    const handleChangePeriodType = (periodType: RevenuePeriodType) => {
        handleChange(RevenueImportRequestField.PERIOD_TYPE, periodType);
        handleChange(RevenueImportRequestField.START_DATE, "");
        handleChange(RevenueImportRequestField.END_DATE, "");
    };

    const handleChangeStartDate = (startDate: Date) => {
        let endDate: Date | undefined;
        switch (revenueImportRequest.period_type.name) {
            case RevenuePeriodType.WEEK.name:
            case RevenuePeriodType.WEEK_BY_WEEK.name:
                // set start date to first day of week (monday) and end date to last day of week (sunday)
                if (startDate.getDay() !== 1) {
                    startDate = new Date(startDate.setDate(startDate.getDate() - startDate.getDay() + 1));
                }
                endDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6));
                break;
            case RevenuePeriodType.MONTH.name:
            case RevenuePeriodType.MONTH_BY_MONTH.name:
                // set start date to first day of month and end date to last day of month
                if (startDate.getDate() !== 1) {
                    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                }
                endDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth() + 1, 0));
                break;
            default:
                break;
        }

        handleChange(RevenueImportRequestField.START_DATE, Formatter.formatDate(moment(startDate).toDate(), Formatter.API_DATE_FORMAT));
        handleChange(RevenueImportRequestField.END_DATE, endDate ? Formatter.formatDate(moment(endDate).toDate(), Formatter.API_DATE_FORMAT) : "");
    };

    const handleChangeEndDate = (endDate: Date) => {
        switch (revenueImportRequest.period_type.name) {
            case RevenuePeriodType.WEEK.name:
            case RevenuePeriodType.WEEK_BY_WEEK.name:
                // set end date to last day of week (sunday)
                if (endDate.getDay() !== 0) {
                    endDate = new Date(endDate.setDate(endDate.getDate() - endDate.getDay() + 7));
                }
                break;
            case RevenuePeriodType.MONTH.name:
            case RevenuePeriodType.MONTH_BY_MONTH.name:
                // set end date to last day of month
                endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
                break;
            default:
                break;
        }

        handleChange(RevenueImportRequestField.END_DATE, Formatter.formatDate(moment(endDate).toDate(), Formatter.API_DATE_FORMAT));
    };

    const handleRemoveFile = () => {
        handleChange(RevenueImportRequestField.FILE_EXTENSION, undefined);
        handleChange(RevenueImportRequestField.FILE_BUFFER, undefined);
        setFileName("");
    };

    const handleBrowseFile = (file?: File) => {
        if (!file) return;

        const regResults = file.name.match(/\.([0-9a-z]+)$/i);
        if (regResults && regResults.length === 2) {
            setFileName(file.name);
            const fileExtension = regResults[1];
            const reader = new FileReader();
            reader.onload = (evt) => {
                if (evt.target?.result !== null && evt.target?.result instanceof ArrayBuffer) {
                    handleChange(RevenueImportRequestField.FILE_EXTENSION, fileExtension);
                    handleChange(RevenueImportRequestField.FILE_BUFFER, evt.target.result);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setLoadingImport(true);
        try {
            revenueImportRequest.user = (await session.getAccount()).email;
            const resp = await session.restRevenuesImport.create(revenueImportRequest);
            if (!!resp.count_deleted) {
                alert.deleteWithSuccess(`${resp.count_deleted} revenues`);
            }
            alert.push(textRevenuesImports("modal.message.import_success", {count: resp.count_imported, total: resp.total}), AlertSeverity.SUCCESS);
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("import revenues", e.message);
            }
        } finally {
            setLoadingImport(false);
            setShowErrors(false);
        }
    };

    const handleDownloadTemplate = async () => {
        try {
            const fileContent = await session.restRevenuesImport.downloadTemplateFile();
            downloadCSV("template-revenues-import", fileContent);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("template file", e.message);
            }
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textRevenuesImports("import.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textRevenuesImports(`modal.field.${RevenueImportRequestField.PLATFORM}`)} required>
                            <Select
                                value={revenueImportRequest.platform.name}
                                options={platforms.map((it) => ({value: it.name, label: it.name, platform: it}))}
                                onChange={(option) => option && handleChange(RevenueImportRequestField.PLATFORM, option.platform)}
                            />
                            {revenueImportRequest.platform.code_name === defaultMultiPlatform.code_name ?
                                <Button
                                    onClick={handleDownloadTemplate}
                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                    size={ButtonSize.SMALL}
                                    icon={Action.DOWNLOAD.icon}
                                > {textRevenuesImports("modal.buttons.download")}
                                </Button> : <></>
                            }
                        </FieldBlock>
                        {revenueImportRequest.platform.code_name !== defaultMultiPlatform.code_name &&
                            <FormLayoutColumns>
                                <FieldBlock label={textRevenuesImports(`modal.field.${RevenueImportRequestField.PERIOD_TYPE}`)} required>
                                    <Select
                                        value={revenueImportRequest.period_type.name}
                                        options={periodTypes.map((it) => ({value: it.name, label: textRevenuesImports(`period_type.${it.name}`), periodType: it}))}
                                        onChange={(option) => option && handleChangePeriodType(option.periodType as RevenuePeriodType)}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textRevenuesImports(`modal.field.${RevenueImportRequestField.START_DATE}`)} required>
                                    <InputDate
                                        type={InputDateType.DATE}
                                        value={revenueImportRequest.start_date}
                                        onChange={(value) => handleChangeStartDate(new Date(value))}
                                    />
                                </FieldBlock>
                                {revenueImportRequest.period_type.name !== RevenuePeriodType.DAY.name &&
                                    <FieldBlock label={textRevenuesImports(`modal.field.${RevenueImportRequestField.END_DATE}`)} required>
                                        <InputDate
                                            type={InputDateType.DATE}
                                            value={revenueImportRequest.end_date}
                                            onChange={(value) => handleChangeEndDate(new Date(value))}
                                            disabled={!RevenuePeriodType.getByName(revenueImportRequest.period_type.name)?.isInterval}
                                        />
                                    </FieldBlock>
                                }
                                <FieldBlock name={RevenueImportRequestField.DELETE} label={textRevenuesImports("modal.field.overwrite")}>
                                    <ToggleSwitch
                                        checked={revenueImportRequest.delete}
                                        onChange={(value) => handleChange(RevenueImportRequestField.DELETE, value)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                        }
                        {revenueImportRequest.platform.source === RevenuePlatformSource.FILE &&
                            <FieldBlock label={textRevenuesImports("modal.field.file_to_import")} content={{direction: FlexContentDirection.COLUMN}} required>
                                <UploadPanel
                                    fileName={fileName}
                                    onChange={handleBrowseFile}
                                    onRemove={handleRemoveFile}
                                    acceptType={revenueImportRequest.platform.acceptedFormats}
                                />
                                {formValidator.isError(RevenueImportRequestField.FILE_BUFFER) &&
                                    <FormLayoutMessage message={textRevenuesImports("modal.message.file_required")} severity={AlertSeverity.DANGER} small/>
                                }
                            </FieldBlock>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isLoadingImport}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalImportRevenues;
