import {Action, Box, BoxProps, ButtonAdd, ButtonSize, ContentBlock, ContentBlockAction, ElementList, ElementListSize, FieldBlock, FormLayoutRows, InputText, LayoutColumns, LayoutRows, Loadable, Table} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {PartnerLicense} from "../../api/model/partner/license/PartnerLicense";
import {PartnerLicenseAuthorization} from "../../api/model/partner/license/PartnerLicenseAuthorization";
import {PartnerLicenseInfo} from "../../api/model/partner/license/PartnerLicenseInfo";
import {PartnerLicenseInfoField} from "../../api/model/partner/license/PartnerLicenseInfoField";
import {PartnerLicenseInfoValueType} from "../../api/model/partner/license/PartnerLicenseInfoValueType";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {LoggedAccount} from "../../common/api/interface/LoggedAccount";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalAddLicenseAuthorization from "../../component/modal/licenses/ModalAddLicenseAuthorization";
import ModalEditLicenseInfo from "../../component/modal/licenses/ModalEditLicenseInfo";
import {LicensePartnersLinks, MainContentHeader, MainContentHeaderAction, PartnerLicenseInfoRow, TagWithAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {detectChanges} from "../../common/utils/portal";
import {PartnerLicenseField} from "../../api/model/partner/license/PartnerLicenseField";
import useAlert from "../../utils/hooks/useAlert";
import ModalAddLicenseInfo from "../../component/modal/licenses/ModalAddLicenseInfo";

function LicensesDetails() {
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const {id} = useParams() as {id: string};
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [account, setAccount] = useState<LoggedAccount>();
    const [license, setLicense] = useState<PartnerLicense>(new PartnerLicense());
    const [initLicense, setInitLicense] = useState<PartnerLicense>(new PartnerLicense());
    const [isShowModalAddLicenseAuthorization, setShowModalAddLicenseAuthorization] = useState(false);
    const [isShowModalAddLicenseInfo, setShowModalAddLicenseInfo] = useState(false);
    const [activeEditLicenseInfo, setActiveEditLicenseInfo] = useState<PartnerLicenseInfo>();

    useEffect(() => {
        (async () => {
            try {
                setAccount(await session.getAccount());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("account", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const partnerLicense = await session.restPartnerLicense.get(+id);
                setLicense(partnerLicense);
                setInitLicense(new PartnerLicense(partnerLicense));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("license", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(license, initLicense));
    }, [license, initLicense]);

    const getCurrentAuthorizations = (): PartnerLicenseAuthorization[] => {
        if (!license || !license.authorizations) return [];
        const res: PartnerLicenseAuthorization[] = [];
        license.authorizations.forEach((value, key: string) => {
            res.push({remove_on_deactivation: value, authorization: key});
        });
        return res;
    };

    const toggleAuthorization = (authorization: string) => {
        if (!license) return;
        const prevValue = license.authorizations.get(authorization);
        const newLicense = new PartnerLicense({...license});
        newLicense.authorizations.set(authorization, !prevValue);
        setLicense(newLicense);
    };

    const handleRemoveAuthorization = (authorization: string) => {
        const newLicense = new PartnerLicense({...license});
        newLicense.authorizations.delete(authorization);
        setLicense(newLicense);
    };

    const handleAddAuthorization = (licenseAuth: PartnerLicenseAuthorization) => {
        const newLicense = new PartnerLicense({...license});
        const newAuthorization = new Map(newLicense.authorizations);
        newAuthorization.set(licenseAuth.authorization, licenseAuth.remove_on_deactivation);
        newLicense.authorizations = newAuthorization;
        setLicense(newLicense);
        setShowModalAddLicenseAuthorization(false);
    };

    const getCurrentInfo = () => {
        if (!license || !license.info) return [];
        const res: PartnerLicenseInfo[] = [];
        license.info.forEach((value, key: string) => {
            res.push(new PartnerLicenseInfo({
                [PartnerLicenseInfoField.KEY]: key,
                [PartnerLicenseInfoField.VALUE]: value,
                [PartnerLicenseInfoField.VALUE_TYPE]: typeof value
            }));
        });
        return res;
    };

    const handleDeleteInfo = (key: string) => {
        const newLicense = new PartnerLicense({...license});
        const newInfo = new Map(newLicense.info);
        newInfo.delete(key);
        newLicense.info = newInfo;
        setLicense(newLicense);
    };

    const handleEditInfo = (info: PartnerLicenseInfo) => {
        const newLicense = new PartnerLicense({...license});
        const newInfo = new Map(newLicense.info);
        const value = info.valueType === PartnerLicenseInfoValueType.OBJECT ? JSON.parse(info.value.replace(/(\r\n|\n|\r)/gm, "")) : info.value;
        newInfo.set(info.key, value);
        newLicense.info = newInfo;
        setLicense(newLicense);
    };

    const handleSave = async () => {
        try {
            await session.restPartnerLicense.update(license);
            setInitLicense(new PartnerLicense(license));
            alert.updateWithSuccess("license");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("license", e.message);
            }
        }
    };

    const hasEmptyField = () => !license.name;

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.LICENSES} element={initLicense.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.LICENSES.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={hasEmptyField() || !isUnsavedChanges}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textLicenses("section.information")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FieldBlock label={textLicenses(`field.${PartnerLicenseField.NAME}`)} required>
                                            <InputText
                                                value={license.name}
                                                onChange={(value) => setLicense((prevState) => new PartnerLicense({...prevState, [PartnerLicenseField.NAME]: value}))}
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textLicenses(`field.${PartnerLicenseField.DESCRIPTION}`)}>
                                            <InputText
                                                value={license.description}
                                                onChange={(value) => setLicense((prevState) => new PartnerLicense({...prevState, [PartnerLicenseField.DESCRIPTION]: value}))}
                                            />
                                        </FieldBlock>
                                        <FieldBlock
                                            label={textLicenses("field.complementary_info")}
                                            actions={
                                                <RestrictedContent allowedTo={Authorization.LICENSES.update}>
                                                    <ButtonAdd
                                                        size={ButtonSize.XSMALL}
                                                        onClick={() => setShowModalAddLicenseInfo(true)}
                                                    />
                                                </RestrictedContent>
                                            }
                                        >
                                            <Table
                                                columns={[
                                                    {width: 30, label: textLicenses("field.key")},
                                                    {width: 65, label: textLicenses("field.value")},
                                                    {width: 5}
                                                ]}
                                                messageIfEmpty={textLicenses("message.no_informations")}
                                            >
                                                {getCurrentInfo().map((info) =>
                                                    <PartnerLicenseInfoRow
                                                        key={info.value}
                                                        info={info}
                                                        onEdit={() => setActiveEditLicenseInfo(info)}
                                                        onRemove={() => handleDeleteInfo(info.key)}
                                                    />
                                                )}
                                            </Table>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                        <Loadable loading={isLoading}>
                            <ContentBlock
                                header={{title: {label: textLicenses("section.authorizations")}, actions: [
                                    <RestrictedContent key="add_license_authorization" allowedTo={Authorization.LICENSES.update}>
                                        <ContentBlockAction
                                            action={Action.ADD}
                                            onClick={() => setShowModalAddLicenseAuthorization(true)}
                                        />
                                    </RestrictedContent>
                                ]}}
                            >
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FieldBlock label={textLicenses("section.authorizations_removed_on_deactivation")}>
                                            <ElementList size={ElementListSize.SMALL} inline>
                                                {getCurrentAuthorizations().filter((it) => it.remove_on_deactivation).map((item) =>
                                                    <TagWithAction
                                                        key={item.authorization}
                                                        value={item.authorization}
                                                        active={true}
                                                        onSelect={() => toggleAuthorization(item.authorization)}
                                                        onDelete={account?.hasAuthorization(Authorization.LICENSES.update || "") ? () => handleRemoveAuthorization(item.authorization) : undefined}
                                                    />
                                                )}
                                            </ElementList>
                                        </FieldBlock>
                                        <FieldBlock label={textLicenses("section.authorizations_other")}>
                                            <ElementList size={ElementListSize.SMALL} inline>
                                                {getCurrentAuthorizations().filter((it) => !it.remove_on_deactivation).map((item) =>
                                                    <TagWithAction
                                                        key={item.authorization}
                                                        value={item.authorization}
                                                        active={true}
                                                        onSelect={() => toggleAuthorization(item.authorization)}
                                                        onDelete={account?.hasAuthorization(Authorization.LICENSES.update || "") ? () => handleRemoveAuthorization(item.authorization) : undefined}
                                                    />
                                                )}
                                            </ElementList>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutColumns>
                    <LicensePartnersLinks licenseId={license.id}/>
                </LayoutRows>
                <ModalAddLicenseInfo
                    active={isShowModalAddLicenseInfo}
                    onSubmit={(info) => {
                        handleEditInfo(info);
                        setShowModalAddLicenseInfo(false);
                    }}
                    onClose={() => setShowModalAddLicenseInfo(false)}
                />
                <ModalEditLicenseInfo
                    initInfo={activeEditLicenseInfo}
                    onSubmit={(info) => {
                        handleEditInfo(info);
                        setActiveEditLicenseInfo(undefined);
                    }}
                    onClose={() => setActiveEditLicenseInfo(undefined)}
                />
                <ModalAddLicenseAuthorization
                    active={isShowModalAddLicenseAuthorization}
                    onSubmit={handleAddAuthorization}
                    onClose={() => setShowModalAddLicenseAuthorization(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default LicensesDetails;
