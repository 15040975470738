import {Box, BoxRadius, ContentBlock} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

type ChangelogItem = {
    date: string;
    items: string[];
}

const CHANGELOG_ITEMS: ChangelogItem[] = [
    {
        date: "26/09/2024",
        items: [
            "CMP Configurations : Ajout d'un nouveau champ pour la gestion de l'affichage des finalités",
            "Sirdata list : Suppression de l'ancien module et mise à jour du nouveau",
            "Customer Orders : Correction d'un bug dans la sélection de l'organisation d'une commande"
        ]
    },
    {
        date: "17/09/2024",
        items: [
            "ADS : Ajout du champ '<i>Origin ignored</i>'",
            "Customer Orders : Correction d'un bug empêchant la mise à jour d'une commande",
            "sGTM : Ajout des champ '<i>Loader path</i>' et '<i>CMP path</i>'",
            "Sirdata list : Ajout du module de création d'une version"
        ]
    },
    {
        date: "16/09/2024",
        items: [
            "Customer Storages : Ajout du nom du fichier uploadé + Modification de la règle de rechargemenet des bases",
            "Dataleaks : Ajout des statistiques",
            "Portal Settings : Création d'un module de configuration des paramètres portail",
            "Sirdata list : Edition des vendors, stacks et purposes",
            "UI/UX : Amélioration de la validation des formulaires sur certaines pages d'édition (en cours)",
            "UI/UX : Correction de bugs mineurs"
        ]
    }
];

const Changelog: FunctionComponent = () => {
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);

    return (
        <ContentBlock header={{title: {label: textHome("changelog.title").toUpperCase()}}} cssClass={"changelog"}>
            <Box radius={BoxRadius.LG} cssClass="changelog__content">
                {CHANGELOG_ITEMS.map((item) =>
                    <div key={item.date} className="changelog__item">
                        <div className="changelog__item__date">{item.date}</div>
                        <ul className="changelog__item__content">
                            {item.items.map((it) =>
                                <li key={it} dangerouslySetInnerHTML={{__html: it}}/>
                            )}
                        </ul>
                    </div>
                )}
            </Box>
        </ContentBlock>
    );
};

export default Changelog;
