import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputEmail,
    InputPhone,
    InputPhoneLocale,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerAccount} from "../../../api/model/partner/PartnerAccount";
import {PartnerAccountField} from "../../../api/model/partner/PartnerAccountField";
import {PartnerAccountProperties} from "../../../api/model/partner/PartnerAccountProperties";
import {Locale} from "../../../common/utils/Locale";
import {CountryCode, CountryIsoCode} from "../../../common/api/model/Country";
import {PartnerAccountPropertiesField} from "../../../api/model/partner/PartnerAccountPropertiesField";
import {Status} from "../../../utils/Status";
import {SelectStatus} from "../../snippet";
import {session} from "../../../api/ApiSession";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";

export type ModalCreatePartnerAccountProps = {
    partnerId: number;
    active: boolean;
    onClose: (refresh: boolean) => void;
};

const ModalCreatePartnerAccount: FunctionComponent<ModalCreatePartnerAccountProps> = ({partnerId, active, onClose}) => {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [account, setAccount] = useState<PartnerAccount>(new PartnerAccount());
    const alert = useAlert();
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-partner-account";

    useEffect(() => {
        if (active) {
            setAccount(new PartnerAccount());
        }
    }, [active]);

    const handleChange = (field: PartnerAccountField, value: any) => {
        setAccount((prevState) => new PartnerAccount({...prevState, [field]: value}));
    };

    const handleChangeProperties = (field: PartnerAccountPropertiesField, value: any) => {
        handleChange(PartnerAccountField.PROPERTIES, {
            ...account.properties,
            [field]: value as PartnerAccountProperties
        });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            account.partner_id = partnerId;
            const newAccount = await session.restPartnerAccount.create(account);
            await session.restPartnerAccount.updateProperties(newAccount.id, account.properties);
            alert.createWithSuccess("partner account");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("partner account", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textPartners("create_account")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textPartners("field.partner_account.status")}>
                                <SelectStatus
                                    value={account.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(PartnerAccountField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.EMAIL}`)} required>
                            <InputEmail
                                value={account.email}
                                onChange={(value) => handleChange(PartnerAccountField.EMAIL, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.FIRST_NAME}`)}>
                                <InputText
                                    value={account.first_name}
                                    onChange={(value) => handleChange(PartnerAccountField.FIRST_NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.LAST_NAME}`)}>
                                <InputText
                                    value={account.last_name}
                                    onChange={(value) => handleChange(PartnerAccountField.LAST_NAME, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.POSTIION}`)}>
                                <InputText
                                    value={account.position}
                                    onChange={(value) => handleChange(PartnerAccountField.POSTIION, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.PHONE}`)}>
                                <InputPhone
                                    value={account.phone}
                                    onChange={(value) => handleChange(PartnerAccountField.PHONE, value)}
                                    locale={Locale.isFrench() ? InputPhoneLocale.FRENCH : InputPhoneLocale.ENGLISH}
                                    country={account.phone_region}
                                    onCountryChange={(value) => handleChange(PartnerAccountField.PHONE_REGION, value as CountryIsoCode || CountryCode.FRANCE.code)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.PROPERTIES}.${PartnerAccountPropertiesField.FRESHSALES_ID}`)}>
                                <InputText
                                    value={account.properties.freshsales_id || ""}
                                    onChange={(value) => handleChangeProperties(PartnerAccountPropertiesField.FRESHSALES_ID, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePartnerAccount;
