import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    FormFieldMessageType,
    FormLayoutRows,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    SearchIds,
    SearchIdsResult,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {AdsAccess} from "../../../api/model/audience/ads/AdsAccess";
import {AdsAccessConfigurationField} from "../../../api/model/audience/ads/AdsAccessConfigurationField";
import {AdsAccessField} from "../../../api/model/audience/ads/AdsAccessField";
import {Partner} from "../../../api/model/partner/Partner";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectPartner, TagPartnerRow} from "../../snippet";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {AdsAccessSearchQuery} from "../../../api/model/audience/ads/AdsAccessSearchQuery";

type ModalAddSharedTaxonomyPartnersProps = {
    active: boolean;
    access: AdsAccess;
    onClose: (refresh: boolean) => void;
};

const ModalAddSharedTaxonomyPartners: FunctionComponent<ModalAddSharedTaxonomyPartnersProps> = ({active, access, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAdsAccess} = useTranslation(TranslationPortalFile.ADS_ACCESS);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [currentSelectedPartners, setCurrentSelectedPartners] = useState<Partner[]>([]);
    const [searchIdResult, setSearchIdResult] = useState<SearchIdsResult>();
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>();
    const [excludedPartners, setExcludedPartners] = useState<Partner[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    setCurrentSelectedPartners([]);

                    const searchQuery = new AdsAccessSearchQuery().withPublicTaxonomy();
                    const searchResult = await session.restAdsAccess.search(searchQuery);
                    const searchResultPartnerIds = new Set(searchResult.elements.map((element) => element.partner_id));

                    const partners = await session.getPartners();
                    const filteredPartners = partners.filter((partner) => searchResultPartnerIds.has(partner.id) && !(access.configuration.shared_taxonomy || []).includes(partner.id));
                    setPartners(filteredPartners);

                    const excludedPartners = partners.filter((partner) => !filteredPartners.includes(partner));
                    setExcludedPartners(excludedPartners);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("partners", e.message);
                    }
                }
            })();
        }
    }, [active, access.configuration.shared_taxonomy, alert]);

    const handleAddPartners = (partners: Partner[]) => {
        setCurrentSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => {
            setHighlightedPartners([]);
        }, 1000);
    };

    const handleAddPartnersByIds = (result: SearchIdsResult) => {
        handleAddPartners(result.matchedItems);
        setSearchIdResult(result);
        setTimeout(() => {
            setSearchIdResult(undefined);
        }, 10000);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const partnerIds = Array.from(new Set([
                ...access.configuration[AdsAccessConfigurationField.SHARED_TAXONOMY] || [],
                ...currentSelectedPartners.map((partner) => partner.id)
            ]));
            await session.restAdsAccess.updateSharedTaxonomy(access.id, partnerIds);
            alert.updateWithSuccess(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`));
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`), e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <ModalNew onClose={() => onClose(false)} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textAdsAccess("modal.add_shared_taxonomy_partners.title")}/>
                </ModalHeader>
                <ModalContent>
                    <FormLayoutRows>
                        <Box>
                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                    <SelectPartner
                                        value={undefined}
                                        onChange={(partner) => partner && handleAddPartners([partner])}
                                        excludedPartners={[...currentSelectedPartners, ...excludedPartners]}
                                    />
                                    <SearchIds items={partners} selectedItems={currentSelectedPartners} onSubmit={handleAddPartnersByIds}/>
                                    {searchIdResult?.messages.map((message) =>
                                        <Alert
                                            key={message.message}
                                            text={message.message}
                                            severity={message.type === FormFieldMessageType.ERROR ? AlertSeverity.DANGER : message.type === FormFieldMessageType.WARNING ? AlertSeverity.WARNING : AlertSeverity.SUCCESS}
                                        />
                                    )}
                                </FormLayoutRows>
                                <FieldBlock
                                    label={textAdsAccess("modal.partners.selected", {count: currentSelectedPartners.length})}
                                    actions={currentSelectedPartners.length &&
                                        <ButtonLink onClick={() => setCurrentSelectedPartners([])}>
                                            {textCommon(Action.REMOVE_ALL.labelKey)}
                                        </ButtonLink>
                                    }
                                >
                                    <ElementList placeholder={textAdsAccess("modal.partners.no_selected")} size={ElementListSize.BIG}>
                                        {currentSelectedPartners.map((partner) =>
                                            <TagPartnerRow
                                                key={partner.id}
                                                partner={partner}
                                                isHighlighted={highlightedPartners?.some(({id}) => partner.id === id)}
                                                onRemove={() => setCurrentSelectedPartners(currentSelectedPartners.filter(({id}) => partner.id !== id))}
                                            />
                                        )}
                                    </ElementList>
                                </FieldBlock>
                            </FormLayoutRows>
                        </Box>
                    </FormLayoutRows>
                </ModalContent>
                <ModalActions>
                    <ButtonLinkCancel onClick={() => onClose(false)}/>
                    <ButtonValidate onClick={handleSubmit} disabled={!currentSelectedPartners.length} loading={isSubmitting}/>
                </ModalActions>
            </ModalNew>
        </>
    );
};

export default ModalAddSharedTaxonomyPartners;
